import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { SnackBarService } from '../../services/snackbar.service';
import { SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {

  showLoader: boolean = true;

  rowsUsers = <any>[];
  columnsUsers = <any>[];

  @Input() prop!: number;
  loadingIndicator: boolean;
  reorderable = true;
  swapColumns = true;
  SelectionType = SelectionType;

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;

  @ViewChild('actionTemplate', { static: false }) actionTemplate: TemplateRef<any>;
  @ViewChild('isBannedTemplate', { static: false }) isBannedTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
  @ViewChild('headerActionTemplate', { static: false }) headerActionTemplate: TemplateRef<any>;

  TableSettings = {
    messages: {
      emptyMessage: `
        <div class="padding-one" style>
        <span>Нямате заявки, за показване.</span>
        </div>
        `
    }
  }
  messages = this.TableSettings.messages;

  constructor(private data: AdminService, private snackBar: SnackBarService) { }

  ngAfterViewInit(): void {
    this.makeTable();
  }

  ngOnInit() {
    this.makeTable();
    this.onGetUsersPaginated(this.offset, this.limit);
  }

  makeTable() {

    this.columnsUsers = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 100, value: 1, cellTemplate: this.isBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

  }

  onGetUsersPaginated(offset: number, limit: number) {
    this.showLoader = true;

    this.data.getUsersPaginated(offset, limit).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }
        this.rowsUsers = data.resultList;
        this.showLoader = false;
      }
    );
  }

  onBanUser(row, isBanned) {
    
    this.showLoader = true;

    let banUserObj = {
      id: row.id,
      isBanned: isBanned
    }

    this.data.banUser(banUserObj).subscribe(
      data => {
        console.log('Data', data);
        this.onGetUsersPaginated(this.offset, this.limit);
        this.showLoader = false;
      }
    );
  }

  userPageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetUsersPaginated(this.offset, this.limit);
    } else if (event == 1) {
      this.resetPagination();
      this.onGetUsersPaginated(this.offset, this.limit);
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ForumService } from '../services/forum.service';
import { SnackBarService } from '../services/snackbar.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSidenav } from '@angular/material/sidenav';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-forum-osnoven',
  templateUrl: './forum-osnoven.component.html',
  styleUrls: ['./forum-osnoven.component.css']
})
export class ForumOsnovenComponent implements OnInit {

  @ViewChild('resetAddTopicForm', {
    static: true
  }) resetAddTopicForm: NgForm;

  @ViewChild('resetEditTopicForm', {
    static: true
  }) resetEditTopicForm: NgForm;

  @ViewChild('resetAddCommentForm', {
    static: true
  }) resetAddCommentForm: NgForm;

  @ViewChild('editSidenavTopic', {
    static: false
  }) editSidenavTopic: MatSidenav;

  @ViewChild('editSidenavComment', {
    static: false
  }) editSidenavComment: MatSidenav;

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End
  
  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number = 0;

  showLoader: boolean = true;
  topics: any = '';
  comments: any = '';

  title: string;
  description: string;

  addTopicFormGroup: FormGroup;
  editTopicFormGroup: FormGroup;
  addCommentFormGroup: FormGroup;
  editCommentFormGroup: FormGroup;
  isLogged: boolean = false;

  topicsShow: boolean = true;
  commentsShow: boolean = false;
  activeCommentUid: string;

  userType: string;
  id: string;

  constructor(private data: ForumService, private snackBar: SnackBarService, private formBuilder: FormBuilder, private cookieService: CookieService) { }

  ngOnInit() {

    if (this.cookieService.get('userEmail') || this.cookieService.get('proEmail')) {
      this.isLogged = true;
    }

    if (this.cookieService.get('proId')) {
      this.id = this.cookieService.get('proId');
    } else if (this.cookieService.get('userId')) {
      this.id = this.cookieService.get('userId');
    }

    this.userType = this.cookieService.get('userType');

    this.onGetAllTopics();
    this.createForms();
  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

  createForms() {
    this.addTopicFormGroup = this.formBuilder.group({
      title: [''],
      description: [''],
    });

    this.editTopicFormGroup = this.formBuilder.group({
      title: [''],
      description: [''],
      uid: ['']
    });

    this.addCommentFormGroup = this.formBuilder.group({
      comment: ['']
    })

    this.editCommentFormGroup = this.formBuilder.group({
      comment: [''],
      uid: ['']
    });
  }

  showTopicsOrComments(choice: string) {
    if (choice == 'topics') {
      this.topicsShow = true;
      this.commentsShow = false;
      this.resetPagination();
      this.onGetAllTopics();
    } else if (choice == 'comments') {
      this.topicsShow = false;
      this.commentsShow = true;
      this.resetPagination();
    } else {
      this.topicsShow = false;
      this.commentsShow = false;
    }
  }

  // Topics

  onGetAllTopics() {

    let categoryId = 0;

    this.data.getAllTopics(categoryId, this.offset, this.limit).subscribe(
      data => {

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }

        this.topics = data.resultList;
        this.showLoader = false;
        console.log('response', data);
      })

  }

  onAddTopic() {

    this.showLoader = true;

    if (this.addTopicFormGroup.controls.title.value && this.addTopicFormGroup.controls.description.value) {

      let addTopicObj = {
        title: this.addTopicFormGroup.controls.title.value,
        description: this.addTopicFormGroup.controls.description.value,
        categoryId: 0
      }

      this.data.addTopic(addTopicObj).subscribe(
        data => {
          this.onGetAllTopics();
          this.addTopicFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onEditTopic() {

    this.showLoader = true;

    if (this.editTopicFormGroup.controls.title.value && this.editTopicFormGroup.controls.description.value) {

      let editTopicObj = {
        uid: this.editTopicFormGroup.controls.uid.value,
        title: this.editTopicFormGroup.controls.title.value,
        description: this.editTopicFormGroup.controls.description.value,
        // categoryId: 0
      }

      this.data.editTopic(editTopicObj).subscribe(
        data => {
          this.onGetAllTopics();
          this.editSidenavTopic.close();
          this.editTopicFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно редактирахте тема.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onDeleteTopic(uid: string) {

    this.showLoader = true;

    this.data.deleteTopic(uid).subscribe(
      data => {
        this.onGetAllTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте вашата тема.', 'Затвори');
      }
    )

  }

  openeditSidenavTopic(topic: any) {
    console.log('editSidenavTopic.open();', topic)

    this.editTopicFormGroup.patchValue({
      title: topic.title,
      description: topic.description,
      uid: topic.uid
    });

    this.editSidenavTopic.open();
  }

  onUpVoteTopic(uid: string) {

    let upVoteObj = {
      uid: uid
    }

    this.data.upVoteTopic(upVoteObj).subscribe(
      data => {
        this.onGetAllTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  onDownVoteTopic(uid: string) {

    let downVoteObj = {
      uid: uid
    }

    this.data.downVoteTopic(downVoteObj).subscribe(
      data => {
        this.onGetAllTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  PageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetAllTopics();
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAllTopics();
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

  // Comments

  goToCommentById(uid: string) {
    console.log('awdawd', uid)
    this.activeCommentUid = uid;
    this.showTopicsOrComments('comments');
    this.onGetAllCommentsById(uid);
  }

  onGetAllCommentsById(uid: string) {
    this.data.getAllComments(this.offset, this.limit, uid).subscribe(
      data => {

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }

        this.comments = data.resultList;
        this.showLoader = false;
        console.log('response', data);
      })
  }

  onAddComment() {

    this.showLoader = true;

    if (this.addCommentFormGroup.controls.comment.value) {

      let addCommentObj = {
        comment: this.addCommentFormGroup.controls.comment.value,
        postUid: this.activeCommentUid
      }

      this.data.addComment(addCommentObj).subscribe(
        data => {
          this.onGetAllCommentsById(this.activeCommentUid);
          this.addCommentFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте коментар.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onEditComment() {

    this.showLoader = true;

    if (this.editCommentFormGroup.controls.comment.value) {

      let editCommentObj = {
        uid: this.editCommentFormGroup.controls.uid.value,
        comment: this.editCommentFormGroup.controls.comment.value,
        // categoryId: 0
      }

      this.data.editComment(editCommentObj).subscribe(
        data => {
          this.onGetAllCommentsById(this.activeCommentUid);
          this.editSidenavComment.close();
          this.editCommentFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно редактирахте коментара.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  onDeleteComment(uid: string) {

    this.showLoader = true;

    this.data.deleteComment(uid).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUid);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте вашият коментар.', 'Затвори');
      }
    )

  }

  openeditSidenavComment(comment: any) {

    this.editCommentFormGroup.patchValue({
      comment: comment.comment,
      uid: comment.uid
    });

    this.editSidenavComment.open();
  }

  onUpVoteComment(uid: string) {

    let upVoteObj = {
      uid: uid
    }

    this.data.upVoteComment(upVoteObj).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUid);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно дадохте положителен рейтинг.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  onDownVoteComment(uid: string) {

    let downVoteObj = {
      uid: uid
    }

    this.data.downVoteComment(downVoteObj).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUid);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно дадохте отрицателен рейтинг.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  close(reason: string) {
    this.editSidenavComment.close();
    this.editSidenavTopic.close();
  }

}

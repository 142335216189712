import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-ds-iskam-da-znam',
  templateUrl: './dialog-ds-iskam-da-znam.component.html',
  styleUrls: ['./dialog-ds-iskam-da-znam.component.css']
})
export class DialogDsIskamDaZnamComponent implements OnInit {

  id: number;
  title: any;
  message: any; 

  constructor(private dialogRef: MatDialogRef<DialogDsIskamDaZnamComponent>, @Inject(MAT_DIALOG_DATA) data: any) { 
    this.id = data.id;
    this.title = data.title;
    this.message = data.message
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { UserService } from '../services/user.service';
import { SnackBarService } from '../services/snackbar.service';
// import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DialogApprovalComponent } from '../dialog-approval/dialog-approval.component';
import { MatDialog, MatDialogConfig } from "@angular/material";

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {

  @ViewChild('resetUserRegisterForm', {
    static: true
  }) resetUserRegisterForm: NgForm;

  @ViewChild('stepper', {
    static: true
  }) addStepper: MatStepper;

  registerUserForm: FormGroup;

  showLoader: boolean = false

  isLinear = false;
  translateSnackBar: any;

  // visibility: string = 'visibility_off';
  // inputType: string = 'password';
  validationCheck: boolean;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: UserService, private dialog: MatDialog, private snackBar: SnackBarService) { }

  ngOnInit() {
    this.createForms();
  }

  createForms() {
    this.registerUserForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [, [Validators.required, Validators.email]],
      // password: ['', Validators.required],
      approval: [, Validators.required]
    });
  }

  onUserRegistration() {
    
    this.showLoader = true;

    let userObj = {
      name: this.registerUserForm.controls.name.value,
      email: this.registerUserForm.controls.email.value,
      // password: this.registerUserForm.controls.password.value
    }
    console.log('slient', userObj)

    if (this.registerUserForm.valid) {
      console.log('vytre valid')
      this.data.userRegistration(userObj).subscribe(
        data => {
          console.log('vytre', data)
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно се регистрирахте, ще получите имейл, за потвърждение.', 'Затвори');
          this.router.navigate(['']);
          this.resetValidation();
        },
        error => {
          if (error.status == 401) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Вече има клиент с такъв имейл.', 'Затвори');
          } else {
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  showApprovalText() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;


    dialogConfig.data = {
      // id: id,
      // title: title,
      // message: message
    };

    this.dialog.open(DialogApprovalComponent, dialogConfig);

  }

  resetValidation() {
    this.resetUserRegisterForm.resetForm();
  }

  // passwordVisibility() {
  //   if (this.visibility == 'visibility') {
  //     this.visibility = 'visibility_off';
  //     this.inputType = 'password';
  //   } else {
  //     this.visibility = 'visibility';
  //     this.inputType = 'text';
  //   }
  // }
  
  onValidCheck(){
    console.log("----", this.validationCheck);
    if (this.registerUserForm.valid) {
      this.validationCheck = true;
    }else{
      this.validationCheck = false;
    }
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class AlgorithmsService {

  constructor(private http: HttpClient) { }

  getAllAlgorithms(offset: number, limit: number) {
    return this.http.get<any>(environment.all_algorithms + 'pagination?offset=' + offset + '&limit=' + limit);
  }

  getAlgorithmById(algorithmId: number) {
    return this.http.get<any>(environment.all_algorithms + algorithmId);
  }

  addFTA(addFTAObj: any){
    return this.http.post<any>(environment.add_algorithm, addFTAObj)
  }

  editFTA(addFTAObj: any){
    return this.http.put<any>(environment.edit_algorithm, addFTAObj)
  }
}

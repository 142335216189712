import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-obrazuvano-sadebno',
  templateUrl: './obrazuvano-sadebno.component.html',
  styleUrls: ['./obrazuvano-sadebno.component.css']
})
export class ObrazuvanoSadebnoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

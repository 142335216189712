import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-show-relation',
  templateUrl: './dialog-show-relation.component.html',
  styleUrls: ['./dialog-show-relation.component.css']
})
export class DialogShowRelationComponent implements OnInit {

  id: number;
  title: any;
  answerNumber: any;

  constructor(private dialogRef: MatDialogRef<DialogShowRelationComponent>, @Inject(MAT_DIALOG_DATA) data: any, private router: Router) {
    this.id = data.id;
    this.title = data.title;
    this.answerNumber = data.answerNumber
  }

  ngOnInit() {
  }

  redirectTo(goTo: string) {
    if (goTo == 'informatsia-i-vrazki') {
      this.router.navigate(['informatsia-i-vrazki']);
    } else {
      this.router.navigate(['registar-oep']);
    }
  }

  close() {
    this.dialogRef.close();
  }

}

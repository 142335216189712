import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OepRegistarService } from '../services/oep-registar.service';
import { SnackBarService } from '../services/snackbar.service';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

export interface Section {
  name: string;
  phone: string;
  email: string;
  competence: string;
  foreignLanguages: string;
  helpCase: string;
}

@Component({
  selector: 'app-oep-registar',
  templateUrl: './oep-registar.component.html',
  styleUrls: ['./oep-registar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OepRegistarComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  professionals: any[] = [];

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;
  showLoader: boolean = true;
  proType: number = 0;


  constructor(private data: OepRegistarService, private snackBar: SnackBarService) { }

  ngOnInit() {
    this.onGetAllProfessioanlsByType(0);
  }

  onGetAllProfessioanlsByType(proType: number) {

    this.proType = proType;

    this.data.getAllProfessioanlsByType(this.offset, this.limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }
        this.professionals = data.resultList;
        this.showLoader = false;
        console.log('awdawdawdawd', this.professionals)
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.professionals = [];
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          console.log('sefsefsefsefsef', this.professionals)
        }
      }
    );

  }

  PageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetAllProfessioanlsByType(this.proType);
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAllProfessioanlsByType(this.proType);
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

}

import { Component, OnInit } from '@angular/core';
import { AlgorithmsService } from '../services/algorithms.service';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-algorithms',
  templateUrl: './algorithms.component.html',
  styleUrls: ['./algorithms.component.css']
})
export class AlgorithmsComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  breakpoint: number;

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;

  resultList = [
    {
      "id": 4,
      "title": "Търся защита на свое нарушено субективно право.",
      "explanation": "Субективно право е признатата от закона възможност едно лице да изисква другите да се съобразяват с негов правен интерес, който е защитен от закона и е въздигнат в задължение за останалите. Субективното право е нарушено, когато е нарушен този правен интерес. Упражняването на субективните права става както с правни, така и с фактически действия."
    },
    {
      "id": 3,
      "title": "THE First Algorithm",
      "explanation": "This is the first algorithm in the universe =O"
    },
    {
      "id": 6,
      "title": "Имам образувано следствено дело.",
      "explanation": "Разпоредбите на Наказателно-процесуалния кодекс (НПК) свързват образуването на следствено дело с едновременното наличие на две предпоставки: законен повод (сигнал до прокурор/следовател; статии в печата; лично признание на дееца пред прокурор за извършеното престъпление и непосредствено разкриване от прокурор/следовател на признаците на извършено престъпление ) и достатъчно данни за извършено престъпление."
    },
    {
      "id": 7,
      "title": "Имам образувано съдебно дело.",
      "explanation": "Съдебно дело е производство пред съд, образувано по предявен от титуляр на субективно право иск за защита на претендираното от него материално право със съдействието на съда (по съдебен ред)."
    }
  ]
  algorithms: any;

  constructor(private data: AlgorithmsService) { }

  ngOnInit() {
    if (window.innerWidth > 1300) {
      this.breakpoint = 5;
    } else if (window.innerWidth > 801 && window.innerWidth <= 1199) {
      this.breakpoint = 3;
    } else if (window.innerWidth > 501 && window.innerWidth <= 800) {
      this.breakpoint = 2;
    } else if (window.innerWidth <= 500) {
      this.breakpoint = 1;
    }
    this.onGetAllAlgorithms();
  }

  onResize(event) {
    if (event.target.innerWidth > 1300) {
      this.breakpoint = 5;
    } else if (event.target.innerWidth > 801 && event.target.innerWidth <= 1199) {
      this.breakpoint = 3;
    } else if (event.target.innerWidth > 501 && event.target.innerWidth <= 800) {
      this.breakpoint = 2;
    } else if (event.target.innerWidth <= 500) {
      this.breakpoint = 1;
    }
  }

  onGetAllAlgorithms() {

    this.data.getAllAlgorithms(this.offset, this.limit).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }
        this.algorithms = data.resultList;
        // this.showLoader = false;
        console.log('data', data);
      }
    );

  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private http: HttpClient) { }

  changePassword(changePasswordObj: object) {
    const data = changePasswordObj;
    return this.http.put<any>(environment.reset_password, data);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HomeService } from '../services/home.service';
import { SnackBarService } from '../services/snackbar.service';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-signali',
  templateUrl: './signali.component.html',
  styleUrls: ['./signali.component.css']
})
export class SignaliComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  @ViewChild('resetSignalsRegisterForm', {
    static: true
  }) resetSignalsRegisterForm: NgForm;

  registerSignalsForm: FormGroup;
  showLoader: boolean;

  constructor(private formBuilder: FormBuilder, private data: HomeService, private snackBar: SnackBarService) { }

  ngOnInit() {
    this.createForms();
  }

  createForms() {
    this.registerSignalsForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      description: ['', Validators.required],
      agree: [, Validators.required]
    });
  }

  onSendSignal() {
    
    this.showLoader = true;

    let signalObj = {
      fullName: this.registerSignalsForm.controls.fullName.value,
      email: this.registerSignalsForm.controls.email.value,
      phone: this.registerSignalsForm.controls.phone.value,
      description: this.registerSignalsForm.controls.description.value,
    }

    if (this.registerSignalsForm.valid) {
      this.data.sendSignal(signalObj).subscribe(
        data => {
          console.log('vytre', data)
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно изпратихте сигнал, очаквайте скоро отговор.', 'Затвори');
          this.resetValidation();
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  resetValidation() {
    this.resetSignalsRegisterForm.resetForm();
  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End
  
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ProfessionalService } from '../services/professional.service';
import { CookieService } from 'ngx-cookie-service';
import { SnackBarService } from '../services/snackbar.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogForgottenPasswordComponent } from '../dialog-forgotten-password/dialog-forgotten-password.component';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  @ViewChild('resetUserLoginForm', {
    static: true
  }) resetUserLoginForm: NgForm;

  @ViewChild('resetProfessionalLoginForm', {
    static: true
  }) resetProfessionalLoginForm: NgForm;

  userLoginForm: FormGroup;
  professionalLoginForm: FormGroup;
  visibility: string = 'visibility_off';
  inputType: string = 'password';

  constructor(private formBuilder: FormBuilder, private router: Router, private userData: UserService, private professionalData: ProfessionalService, private cookieService: CookieService, private snackBar: SnackBarService, private dialog: MatDialog) { }

  userId: string;
  userName: string;
  userEmail: string;

  professionalId: string;
  professionalName: string;
  professionalEmail: string;

  showLoader: boolean = false;

  ngOnInit() {
    this.createForms();
  }

  createForms() {
    this.userLoginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.professionalLoginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onLoginUser() {

    this.showLoader = true;

    if (this.userLoginForm.valid) {

      let userObj = {
        email: this.userLoginForm.controls.login.value,
        password: this.userLoginForm.controls.password.value,
      }
      // sessionStorage.setItem('clientEmail', client.login);

      this.userData.loginUser(userObj).subscribe(
        data => {
          console.log('respons data', data)
          this.cookieService.set('userId', data['id']);
          this.cookieService.set('userName', data['name']);
          this.cookieService.set('userEmail', data['email']);
          this.cookieService.set('token', data['token']);
          this.cookieService.set('userType', data['userType']);
          console.log('All cookies', this.cookieService.getAll())
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно влязохте в профила си!', 'Затвори');
          this.resetValidation();
          this.router.navigate(['user-profile/' + data['id']]);
        },
        error => {
          if (error.status == 401) {
            this.snackBar.openSnackWrongBar('Акаунтът, с който се опитвате да влезете, е неправилен.', 'Затвори');
            this.showLoader = false;
          }
        }
      );

    } else {
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
      this.showLoader = false;
    }

  }

  onLoginProfessional() {

    this.showLoader = true;

    if (this.professionalLoginForm.valid) {
      console.log('Inside Validation')

      let professionalObj = {
        email: this.professionalLoginForm.controls.login.value,
        password: this.professionalLoginForm.controls.password.value,
      }

      this.professionalData.loginProfessional(professionalObj).subscribe(
        data => {
          console.log('respons data', data)
          this.cookieService.set('proId', data['id']);
          this.cookieService.set('proName', data['name']);
          this.cookieService.set('proEmail', data['email']);
          this.cookieService.set('proPhone', data['phone']);
          this.cookieService.set('proType', data['type']);
          this.cookieService.set('proForeignLanguages', data['foreignLanguages']);
          this.cookieService.set('token', data['token']);
          this.cookieService.set('userType', data['userType']);
          this.cookieService.set('registerAgreement', data['registerAgreement']);

          this.cookieByType(data);

          console.log('All cookies', this.cookieService.getAll())
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно влязохте в профила си!', 'Затвори');
          this.resetValidation();
          this.router.navigate(['professional-profile/' + data['id']]);
        },
        error => {
          if (error.status == 401) {
            this.snackBar.openSnackWrongBar('Акаунтът, с който се опитвате да влезете, е неправилен.', 'Затвори');
            this.showLoader = false;
          }
        }
      );
    } else {
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
      this.showLoader = false;
    }

  }

  cookieByType(data: any) {
    if (data.type == 0) {
      this.cookieService.set('lawyersCollege', data.details.lawyersCollege);
      this.cookieService.set('propertyLaw', data.details.propertyLaw);
      this.cookieService.set('inheritanceLaw', data.details.inheritanceLaw);
      this.cookieService.set('commercialLaw', data.details.commercialLaw);
      this.cookieService.set('copyrightRelatedLaw', data.details.copyrightRelatedLaw);
      this.cookieService.set('laborLaw', data.details.laborLaw);
      this.cookieService.set('familyLaw', data.details.familyLaw);
      this.cookieService.set('contractualLaw', data.details.contractualLaw);
      this.cookieService.set('criminalLaw', data.details.criminalLaw);
      this.cookieService.set('financialLaw', data.details.financialLaw);
      this.cookieService.set('taxLaw', data.details.taxLaw);
      this.cookieService.set('insuranceLaw', data.details.insuranceLaw);
      this.cookieService.set('internationalPrivateLaw', data.details.internationalPrivateLaw);
      this.cookieService.set('internationalPublicLaw', data.details.internationalPublicLaw);
    } else if (data.type == 1) {
      this.cookieService.set('competencies', data.details.competencies);
    } else if (data.type == 2) {
      this.cookieService.set('lawBranch', data.details.lawBranch);
    } else if (data.type == 3) {
      this.cookieService.set('regionOfOperation', data.details.regionOfOperation);
      this.cookieService.set('registrationNumber', data.details.registrationNumber);
    } else if (data.type == 4) {
      this.cookieService.set('status', data.details.status);
    } else if (data.type == 5) {
      this.cookieService.set('competencies', data.details.competencies);
      this.cookieService.set('status', data.details.status);
    } else if (data.type == 6) {
      this.cookieService.set('ombudsmanType', data.details.ombudsmanType);
      this.cookieService.set('workRegion', data.details.workRegion);
    }
  }

  onForgottenPassword(userType) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;


    dialogConfig.data = {
      id: 1,
      userType: userType
    };

    this.dialog.open(DialogForgottenPasswordComponent, dialogConfig);

  }

  resetValidation() {
    this.resetUserLoginForm.resetForm();
    this.resetProfessionalLoginForm.resetForm();
  }

  passwordVisibility() {
    if (this.visibility == 'visibility') {
      this.visibility = 'visibility_off';
      this.inputType = 'password';
    } else {
      this.visibility = 'visibility';
      this.inputType = 'text';
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  adminLogin(adminObj: any) {
    const data = adminObj;
    return this.http.post<any>(environment.admin_login, data);
  }

  adminRegistration(adminObj: any) {
    const data = adminObj;
    return this.http.post<any>(environment.admin_registration, data);
  }

  adminEdit(adminObj: any) {
    const data = adminObj;
    return this.http.put<any>(environment.admin_edit_account, data);
  }

  editProfileInfo(adminEditObj: any) {
    const data = adminEditObj;
    return this.http.put<any>(environment.admin_edit, data);
  }

  resetPassword(changePassObj: any) {
    const data = changePassObj;
    return this.http.put<any>(environment.admin_change_password, data);
  }

  getAdminsPaginated(offset: number, limit: number) {
    return this.http.get<any>(environment.get_admins + '?offset=' + offset + '&limit=' + limit);
  }

  deleteAdmin(id: string) {
    return this.http.delete<any>(environment.delete_admin + id);
  }

  getUsersPaginated(offset: number, limit: number) {
    return this.http.get<any>(environment.get_users + '?offset=' + offset + '&limit=' + limit);
  }

  banUser(banUserObj: any) {
    const data = banUserObj;
    return this.http.put<any>(environment.ban_user, data);
  }

  getProfessionalsPaginated(offset: number, limit: number, proType: number) {
    return this.http.get<any>(environment.get_professionals + proType + '/pagination' + '?offset=' + offset + '&limit=' + limit);
  }

  banProfessional(banProfessionalObj: any) {
    const data = banProfessionalObj;
    return this.http.put<any>(environment.ban_professionals, data);
  }

  getNewsPaginated(offset: number, limit: number) {
    return this.http.get<any>(environment.get_news + '?offset=' + offset + '&limit=' + limit);
  }

  addNews(adminObj) {
    const data = adminObj;
    return this.http.post<any>(environment.add_news, data);
  }

  editNews(adminObj) {
    const data = adminObj;
    return this.http.put<any>(environment.add_news, data);
  }

  deleteNews(id: string) {
    return this.http.delete<any>(environment.delete_news + id);
  }

  uploadImage(fileType: any, uploadImageObj: any) {

    const data = uploadImageObj;

    return this.http.post(environment.upload_news_image + fileType, data, {
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // },
      reportProgress: true,
      observe: 'events'
    });
  }

  forgottenPassword(email: any) {
    const data = email;
    return this.http.put<any>(environment.forgotten_password_admin, data);
  }

  confirmPassword(passObj) {
    const data = passObj;
    return this.http.put<any>(environment.confirm_password, data);
  }
}

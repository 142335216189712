import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class ForumService {

  constructor(private http: HttpClient) { }

  // Public

  getAllTopics(categoryId: number, offset: number, limit: number) {
    return this.http.get<any>(environment.all_topics + categoryId + '?offset=' + offset + '&limit=' + limit);
  }

  addTopic(addTopicObj: any) {
    const data = addTopicObj;
    return this.http.post<any>(environment.add_topic, data);
  }

  editTopic(editObj: object) {
    const data = editObj;
    return this.http.put<any>(environment.edit_topic, data);
  }

  deleteTopic(uid: string) {
    return this.http.delete<any>(environment.delete_topic + uid);
  }

  getAllComments(offset: number, limit: number, uid: string) {
    return this.http.get<any>(environment.all_comments + uid + '?offset=' + offset + '&limit=' + limit);
  }

  addComment(addCommentObj: any) {
    const data = addCommentObj;
    return this.http.post<any>(environment.add_comment, data);
  }

  editComment(editObj: object) {
    const data = editObj;
    return this.http.put<any>(environment.edit_comment, data);
  }

  deleteComment(uid: string) {
    return this.http.delete<any>(environment.delete_comment + uid);
  }

  upVoteTopic(upVoteObj: object) {
    const data = upVoteObj;
    return this.http.put<any>(environment.topic_up_vote, data);
  }

  downVoteTopic(downVoteObj: object) {
    const data = downVoteObj;
    return this.http.put<any>(environment.topic_down_vote, data);
  }

  upVoteComment(upVoteObj: object) {
    const data = upVoteObj;
    return this.http.put<any>(environment.comment_up_vote, data);
  }

  downVoteComment(downVoteObj: object) {
    const data = downVoteObj;
    return this.http.put<any>(environment.comment_down_vote, data);
  }

  // Private

  getAllPrivateTopics(categoryId: number, offset: number, limit: number) {
    return this.http.get<any>(environment.all_private_topics + '?offset=' + offset + '&limit=' + limit);
  }

  addPrivateTopic(addTopicObj: any) {
    const data = addTopicObj;
    return this.http.post<any>(environment.add_private_topic, data);
  }

  getAllPrivateComments(offset: number, limit: number, uid: string) {
    return this.http.get<any>(environment.all_private_comments + uid + '?offset=' + offset + '&limit=' + limit);
  }

  addPrivateComment(addCommentObj: any) {
    const data = addCommentObj;
    return this.http.post<any>(environment.add_private_comment, data);
  }

  editPrivateTopic(editObj: object) {
    const data = editObj;
    return this.http.put<any>(environment.edit_private_topic, data);
  }

  deletePrivateTopic(uid: string) {
    return this.http.delete<any>(environment.delete_private_topic + uid);
  }

  editPrivateComment(editObj: object) {
    const data = editObj;
    return this.http.put<any>(environment.edit_private_comment, data);
  }

  deletePrivateComment(uid: string) {
    return this.http.delete<any>(environment.delete_private_comment + uid);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vatreshna-sreda',
  templateUrl: './vatreshna-sreda.component.html',
  styleUrls: ['./vatreshna-sreda.component.css']
})
export class VatreshnaSredaComponent implements OnInit {

  breakpoint: number;

  constructor() { }

  ngOnInit() {
    if(window.innerWidth > 1300){
      this.breakpoint =  4 ;
    }else if(window.innerWidth > 701 && window.innerWidth <= 1199){
      this.breakpoint =  2 ;
    }else if(window.innerWidth <= 700){
      this.breakpoint =  1 ;
    }
  }

  onResize(event) {
    if (event.target.innerWidth > 1300) {
      this.breakpoint = 4;
    } else if (event.target.innerWidth > 701 && event.target.innerWidth <= 1199) {
      this.breakpoint = 2;
    } else if (event.target.innerWidth <= 700) {
      this.breakpoint = 1;
    }
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class NoviniService {

  constructor(private http: HttpClient) { }

  getAllNews(offset: number, limit: number) {
    return this.http.get<any>(environment.all_news + '/pagination?offset=' + offset + '&limit=' + limit);
  }

  getNewsById(newsId: number) {
    return this.http.get<any>(environment.all_news + '/' + newsId);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
import { SnackBarService } from '../services/snackbar.service';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  userId: string;
  userName: string;
  userEmail: string;

  enableEdit: boolean = true;
  showLoader: boolean = true;

  @ViewChild('resetEditForm', {
    static: true
  }) resetEditForm: NgForm;

  @ViewChild('resetPartnerRegFormSecond', {
    static: true
  }) resetResetPasswordRegForm: NgForm;

  editFormGroup: FormGroup;
  resetPasswordFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder, private cookieService: CookieService, private data: UserService, private snackBar: SnackBarService) { }

  ngOnInit() {

    this.userId = this.cookieService.get('userId');
    this.userName = this.cookieService.get('userName');
    this.userEmail = this.cookieService.get('userEmail');

    console.log('In Profile all cookies', this.cookieService.getAll());

    this.createForms();
    this.disableFields();
  }

  createForms() {
    this.editFormGroup = this.formBuilder.group({
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.resetPasswordFormGroup = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required]
    });
  }


  onEditProfileInfo() {

    this.showLoader = true;

    if (this.editFormGroup.controls.userName.valid) {

      let userEditObj = {
        id: parseInt(this.userId),
        name: this.editFormGroup.controls.userName.value
      }

      this.data.editProfileInfo(userEditObj).subscribe(
        data => {
          this.cookieService.set('userName', data['name']);
          // sessionStorage.setItem('userFirstName', this.userFirstName);
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно променихте информацията за вашия профил.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  onResetPassword() {
    console.log('Lets change it!');

    this.showLoader = true;

    if (this.resetPasswordFormGroup.controls.newPassword.valid && this.resetPasswordFormGroup.controls.oldPassword.valid) {

      let changePassObj = {
        id: parseInt(this.userId),
        newPassword: this.resetPasswordFormGroup.controls.newPassword.value,
        oldPassword: this.resetPasswordFormGroup.controls.oldPassword.value
      }

      this.data.resetPassword(changePassObj).subscribe(
        data => {
          console.log('awdawdawd', data)
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно променихте паролата си.', 'Затвори');
        },
        error => {
          if (error.status == 401) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Грешно сте въвели старата парола', 'Затвори');
          } else if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  enableField(status: boolean) {
    if (status == false) {
      this.enableFields();
      this.enableEdit = status;
    } else {
      this.disableFields();
      this.enableEdit = status;
    }
  }

  disableFields() {
    this.editFormGroup.controls.userName.disable();
    this.editFormGroup.controls.email.disable();
    this.resetPasswordFormGroup.controls.oldPassword.disable();
    this.resetPasswordFormGroup.controls.newPassword.disable();
    this.showLoader = false;
  }

  enableFields() {
    this.editFormGroup.controls.userName.enable();
    this.editFormGroup.controls.email.disable();
    this.resetPasswordFormGroup.controls.oldPassword.enable();
    this.resetPasswordFormGroup.controls.newPassword.enable();
  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

}

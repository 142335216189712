import { Component, OnInit } from '@angular/core';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  constructor() { }

  ngOnInit() { }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

}

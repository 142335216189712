import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { AdminService } from '../../services/admin.service';
import { SnackBarService } from '../../services/snackbar.service';
import { professionals } from '../../services/constants.service';

@Component({
  selector: 'app-admin-professionals',
  templateUrl: './admin-professionals.component.html',
  styleUrls: ['./admin-professionals.component.css']
})
export class AdminProfessionalsComponent implements OnInit {

  showLoader: boolean = true;

  lawyer: number = professionals.type[0].value;
  arbitrator: number = professionals.type[1].value;
  mediator: number = professionals.type[2].value;
  notary: number = professionals.type[3].value;
  bailiff: number = professionals.type[4].value;
  jurist: number = professionals.type[5].value;
  ombudsman: number = professionals.type[6].value;

  rowsLawyers = <any>[];
  columnsLawyers = <any>[];

  rowsArbitrators = <any>[];
  columnsArbitrators = <any>[];

  rowsMediators = <any>[];
  columnsMediators = <any>[];

  rowsNotaries = <any>[];
  columnsNotaries = <any>[];

  rowsBailiffs = <any>[];
  columnsBailiffs = <any>[];

  rowsJurists = <any>[];
  columnsJurists = <any>[];

  rowsOmbudsmans = <any>[];
  columnsOmbudsmans = <any>[];

  @Input() prop!: number;
  loadingIndicator: boolean;
  reorderable = true;
  swapColumns = true;
  SelectionType = SelectionType;

  limitLawyers: number = 10;
  offsetLawyers: number = 0;
  cPageLawyers: number = 1;
  totalResultLawyers: number;

  limitArbitrators: number = 10;
  offsetArbitrators: number = 0;
  cPageArbitrators: number = 1;
  totalResultArbitrators: number;

  limitMediators: number = 10;
  offsetMediators: number = 0;
  cPageMediators: number = 1;
  totalResultMediators: number;

  limitNotaries: number = 10;
  offsetNotaries: number = 0;
  cPageNotaries: number = 1;
  totalResultNotaries: number;

  limitBailiffs: number = 10;
  offsetBailiffs: number = 0;
  cPageBailiffs: number = 1;
  totalResultBailiffs: number;

  limitJurists: number = 10;
  offsetJurists: number = 0;
  cPageJurists: number = 1;
  totalResultJurists: number;

  limitOmbudsmans: number = 10;
  offsetOmbudsmans: number = 0;
  cPageOmbudsmans: number = 1;
  totalResultOmbudsmans: number;

  @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: false }) actionTemplate: TemplateRef<any>;
  @ViewChild('headerActionTemplate', { static: false }) headerActionTemplate: TemplateRef<any>;

  //Tooltips
  @ViewChild('civilRights', { static: false }) civilRights: TemplateRef<any>;
  @ViewChild('criminalLaw', { static: false }) criminalLaw: TemplateRef<any>;
  @ViewChild('аdministrativeLaw', { static: false }) аdministrativeLaw: TemplateRef<any>;
  @ViewChild('internationalLaw', { static: false }) internationalLaw: TemplateRef<any>;

  @ViewChild('bailiffsStatus', { static: false }) bailiffsStatus: TemplateRef<any>;
  @ViewChild('juristStatus', { static: false }) juristStatus: TemplateRef<any>;
  @ViewChild('ombudsmansType', { static: false }) ombudsmansType: TemplateRef<any>;

  //Active
  @ViewChild('isLawyerBannedTemplate', { static: false }) isLawyerBannedTemplate: TemplateRef<any>;
  @ViewChild('isArbitratorBannedTemplate', { static: false }) isArbitratorBannedTemplate: TemplateRef<any>;
  @ViewChild('isMediatorBannedTemplate', { static: false }) isMediatorBannedTemplate: TemplateRef<any>;
  @ViewChild('isNotarieBannedTemplate', { static: false }) isNotarieBannedTemplate: TemplateRef<any>;
  @ViewChild('isBailiffBannedTemplate', { static: false }) isBailiffBannedTemplate: TemplateRef<any>;
  @ViewChild('isJuristBannedTemplate', { static: false }) isJuristBannedTemplate: TemplateRef<any>;
  @ViewChild('isOmbudsmanBannedTemplate', { static: false }) isOmbudsmanBannedTemplate: TemplateRef<any>;

  TableSettings = {
    messages: {
      emptyMessage: `
        <div class="padding-one" style>
        <span>Нямате заявки, за показване.</span>
        </div>
        `
    }
  }
  messages = this.TableSettings.messages;

  constructor(private data: AdminService, private snackBar: SnackBarService) { }

  ngAfterViewInit(): void {
    this.makeTable();
  }

  ngOnInit() {
    this.makeTable();
    this.onGetLawyersPaginated(this.offsetLawyers, this.limitLawyers, this.lawyer);
    this.onGetArbitratorsPaginated(this.offsetArbitrators, this.limitArbitrators, this.arbitrator);
    this.onGetMediatorsPaginated(this.offsetMediators, this.limitMediators, this.mediator);
    this.onGetNotariesPaginated(this.offsetNotaries, this.limitNotaries, this.notary);
    this.onGetBailiffsPaginated(this.offsetBailiffs, this.limitBailiffs, this.bailiff);
    this.onGetJuristsPaginated(this.offsetJurists, this.limitJurists, this.jurist);
    this.onGetOmbudsmansPaginated(this.offsetOmbudsmans, this.limitOmbudsmans, this.ombudsman);
  }

  makeTable() {

    this.columnsLawyers = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Адвокатска колегия', prop: "lawyersCollege", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Гражданско право', width: 50, sortable: true, cellTemplate: this.civilRights, headerTemplate: this.headerTemplate },
      { name: 'Наказателно право', width: 50, sortable: true, cellTemplate: this.criminalLaw, headerTemplate: this.headerTemplate },
      { name: 'Административно право', width: 50, sortable: true, cellTemplate: this.аdministrativeLaw, headerTemplate: this.headerTemplate },
      { name: 'Международно право', width: 50, sortable: true, cellTemplate: this.internationalLaw, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isLawyerBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

    this.columnsArbitrators = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Компетентност', prop: "competencies", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isArbitratorBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

    this.columnsMediators = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Отрасъл/дял на правото с разрешаване на казуси', prop: "lawBranch", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isMediatorBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

    this.columnsNotaries = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Район на действие', prop: "regionOfOperation", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Регистрационен номер', prop: "registrationNumber", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isNotarieBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

    this.columnsBailiffs = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Статус', prop: "status", width: 100, value: 1, cellTemplate: this.bailiffsStatus, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isBailiffBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

    this.columnsJurists = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Компетентност', prop: "competencies", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Статус', prop: "status", width: 100, cellTemplate: this.juristStatus, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isJuristBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

    this.columnsOmbudsmans = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Номер', prop: "phone", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Чужди езици', prop: "foreignLanguages", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Община', prop: "workRegion", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Вид', prop: "ombudsmanType", width: 100, value: 1, cellTemplate: this.ombudsmansType, headerTemplate: this.headerTemplate },
      { name: 'Активност', prop: "isBanned", width: 50, value: 1, cellTemplate: this.isOmbudsmanBannedTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

  }

  // Lawyers
  onGetLawyersPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultLawyers = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultLawyers === data.totalResult) {
          this.totalResultLawyers = this.totalResultLawyers - Math.floor(data.totalResult / 10);
        }
        this.rowsLawyers = data.resultList;
        this.showLoader = false;
      }
    );
  }

  onBanProfessional(row, isBanned) {

    this.showLoader = true;

    console.log('dadawdawdwa', row)
    let banProfessionalObj = {
      id: row.id,
      isBanned: isBanned
    }

    this.data.banProfessional(banProfessionalObj).subscribe(
      data => {

        switch (row.type) {
          case 0:
            this.onGetLawyersPaginated(this.offsetLawyers, this.limitLawyers, this.lawyer);
            break;
          case 1:
            this.onGetArbitratorsPaginated(this.offsetArbitrators, this.limitArbitrators, this.arbitrator);
            break;
          case 2:
            this.onGetMediatorsPaginated(this.offsetMediators, this.limitMediators, this.mediator);
            break;
          case 3:
            this.onGetNotariesPaginated(this.offsetNotaries, this.limitNotaries, this.notary);
            break;
          case 4:
            this.onGetBailiffsPaginated(this.offsetBailiffs, this.limitBailiffs, this.bailiff);
            break;
          case 5:
            this.onGetJuristsPaginated(this.offsetJurists, this.limitJurists, this.jurist);
            break;
          case 6:
            this.onGetOmbudsmansPaginated(this.offsetOmbudsmans, this.limitOmbudsmans, this.ombudsman);
            break;
          default:
            this.onGetLawyersPaginated(this.offsetLawyers, this.limitLawyers, row.type);
            break;
        }

        this.showLoader = false;
      });

  }

  LawyerPageChanged(event) {
    this.cPageLawyers = event;
    this.offsetLawyers = 10 * (event - 1);
    if (this.limitLawyers == this.offsetLawyers || this.limitLawyers < this.offsetLawyers) {
      this.onGetLawyersPaginated(this.offsetLawyers, this.limitLawyers, this.lawyer);
    } else if (event == 1) {
      this.resetLawyersPagination();
      this.onGetLawyersPaginated(this.offsetLawyers, this.limitLawyers, this.lawyer);
    }
  }

  resetLawyersPagination() {
    this.limitLawyers = 10;
    this.offsetLawyers = 0;
    this.cPageLawyers = 1;
  }

  // Arbitrators
  onGetArbitratorsPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultArbitrators = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultArbitrators === data.totalResult) {
          this.totalResultArbitrators = this.totalResultArbitrators - Math.floor(data.totalResult / 10);
        }
        this.rowsArbitrators = data.resultList;
        this.showLoader = false;
      }
    );
  }

  arbitratorPageChanged(event) {
    this.cPageArbitrators = event;
    this.offsetArbitrators = 10 * (event - 1);
    if (this.limitArbitrators == this.offsetArbitrators || this.limitArbitrators < this.offsetArbitrators) {
      this.onGetArbitratorsPaginated(this.offsetArbitrators, this.limitArbitrators, this.arbitrator);
    } else if (event == 1) {
      this.resetArbitratorsPagination();
      this.onGetArbitratorsPaginated(this.offsetArbitrators, this.limitArbitrators, this.arbitrator);
    }
  }

  resetArbitratorsPagination() {
    this.limitArbitrators = 10;
    this.offsetArbitrators = 0;
    this.cPageArbitrators = 1;
  }

  // Mediators
  onGetMediatorsPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultMediators = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultMediators === data.totalResult) {
          this.totalResultMediators = this.totalResultMediators - Math.floor(data.totalResult / 10);
        }
        this.rowsMediators = data.resultList;
        this.showLoader = false;
      }
    );
  }

  mediatorPageChanged(event) {
    this.cPageMediators = event;
    this.offsetMediators = 10 * (event - 1);
    if (this.limitMediators == this.offsetMediators || this.limitMediators < this.offsetMediators) {
      this.onGetMediatorsPaginated(this.offsetMediators, this.limitMediators, this.mediator);
    } else if (event == 1) {
      this.resetMediatorsPagination();
      this.onGetMediatorsPaginated(this.offsetMediators, this.limitMediators, this.mediator);
    }
  }

  resetMediatorsPagination() {
    this.limitMediators = 10;
    this.offsetMediators = 0;
    this.cPageMediators = 1;
  }

  // Notaries
  onGetNotariesPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultNotaries = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultNotaries === data.totalResult) {
          this.totalResultNotaries = this.totalResultNotaries - Math.floor(data.totalResult / 10);
        }
        this.rowsNotaries = data.resultList;
        this.showLoader = false;
      }
    );
  }

  notaryPageChanged(event) {
    this.cPageNotaries = event;
    this.offsetNotaries = 10 * (event - 1);
    if (this.limitNotaries == this.offsetNotaries || this.limitNotaries < this.offsetNotaries) {
      this.onGetNotariesPaginated(this.offsetNotaries, this.limitNotaries, this.notary);
    } else if (event == 1) {
      this.resetNotariesPagination();
      this.onGetNotariesPaginated(this.offsetNotaries, this.limitNotaries, this.notary);
    }
  }

  resetNotariesPagination() {
    this.limitNotaries = 10;
    this.offsetNotaries = 0;
    this.cPageNotaries = 1;
  }

  // Bailiffs
  onGetBailiffsPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultBailiffs = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultBailiffs === data.totalResult) {
          this.totalResultBailiffs = this.totalResultBailiffs - Math.floor(data.totalResult / 10);
        }
        this.rowsBailiffs = data.resultList;
        this.showLoader = false;
      }
    );
  }

  bailiffPageChanged(event) {
    this.cPageBailiffs = event;
    this.offsetBailiffs = 10 * (event - 1);
    if (this.limitBailiffs == this.offsetBailiffs || this.limitBailiffs < this.offsetBailiffs) {
      this.onGetNotariesPaginated(this.offsetBailiffs, this.limitBailiffs, this.bailiff);
    } else if (event == 1) {
      this.resetNotariesPagination();
      this.onGetNotariesPaginated(this.offsetBailiffs, this.limitBailiffs, this.bailiff);
    }
  }

  resetBailiffsPagination() {
    this.limitBailiffs = 10;
    this.offsetBailiffs = 0;
    this.cPageBailiffs = 1;
  }

  // Jurist
  onGetJuristsPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultJurists = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultJurists === data.totalResult) {
          this.totalResultJurists = this.totalResultJurists - Math.floor(data.totalResult / 10);
        }
        this.rowsJurists = data.resultList;
        this.showLoader = false;
      }
    );
  }

  juristPageChanged(event) {
    this.cPageJurists = event;
    this.offsetJurists = 10 * (event - 1);
    if (this.limitJurists == this.offsetJurists || this.limitJurists < this.offsetJurists) {
      this.onGetNotariesPaginated(this.offsetJurists, this.limitJurists, this.jurist);
    } else if (event == 1) {
      this.resetNotariesPagination();
      this.onGetNotariesPaginated(this.offsetJurists, this.limitJurists, this.jurist);
    }
  }

  resetJuristsPagination() {
    this.limitJurists = 10;
    this.offsetJurists = 0;
    this.cPageJurists = 1;
  }

  // Ombudsman
  onGetOmbudsmansPaginated(offset: number, limit: number, proType: number) {
    this.showLoader = true;

    this.data.getProfessionalsPaginated(offset, limit, proType).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResultOmbudsmans = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultOmbudsmans === data.totalResult) {
          this.totalResultOmbudsmans = this.totalResultOmbudsmans - Math.floor(data.totalResult / 10);
        }
        this.rowsOmbudsmans = data.resultList;
        this.showLoader = false;
      }
    );
  }

  ombudsmanPageChanged(event) {
    this.cPageOmbudsmans = event;
    this.offsetOmbudsmans = 10 * (event - 1);
    if (this.limitOmbudsmans == this.offsetOmbudsmans || this.limitOmbudsmans < this.offsetOmbudsmans) {
      this.onGetNotariesPaginated(this.offsetOmbudsmans, this.limitOmbudsmans, this.ombudsman);
    } else if (event == 1) {
      this.resetNotariesPagination();
      this.onGetNotariesPaginated(this.offsetOmbudsmans, this.limitOmbudsmans, this.ombudsman);
    }
  }

  resetOmbudsmansPagination() {
    this.limitOmbudsmans = 10;
    this.offsetOmbudsmans = 0;
    this.cPageOmbudsmans = 1;
  }

}

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SnackBarService } from 'src/app/services/snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  adminId: string;

  constructor(private cookieService: CookieService, private snackBar: SnackBarService, private router: Router) { }

  ngOnInit() {
    this.adminId = this.cookieService.get('adminId');
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }

  logout() {
    this.cookieService.deleteAll();
    this.router.navigate(['']);
    this.snackBar.openSnackBar('Успешно излязохте от профила си.', 'Затвори');
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  sendSignal(signalObj: any) {
    const data = signalObj;
    return this.http.post<any>(environment.signal, data);
  }
}

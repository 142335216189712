import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  userRegistration(userObj: any) {
    const data = userObj;
    return this.http.post<any>(environment.user_registration, data);
  }

  loginUser(userObj: any) {
    const data = userObj;
    return this.http.post<any>(environment.user_login, data);
  }

  resetPassword(changePassObj: any) {
    const data = changePassObj;
    return this.http.put<any>(environment.change_password, data);
  }

  editProfileInfo(userEditObj: any) {
    const data = userEditObj;
    return this.http.put<any>(environment.user_edit, data);
  }

  forgottenPassword(email: any) {
    const data = email;
    return this.http.put<any>(environment.forgotten_password_user, data);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ProfessionalService } from '../services/professional.service';
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'app-professional-profile',
  templateUrl: './professional-profile.component.html',
  styleUrls: ['./professional-profile.component.css']
})
export class ProfessionalProfileComponent implements OnInit {

  // General Properties for Professional
  proId: string;
  proName: string;
  proEmail: string;
  proPhone: string;
  proType: string;
  approval: boolean;
  proForeignLanguages: string;
  registerAgreement: string;

  isLogged: boolean = false;
  enableEdit: boolean = true;
  showLoader: boolean = true;

  @ViewChild('resetEditForm', {
    static: true
  }) resetEditForm: NgForm;

  @ViewChild('resetPartnerRegFormSecond', {
    static: true
  }) resetResetPasswordRegForm: NgForm;

  @ViewChild('resetLawyerRegisterForm', {
    static: true
  }) resetLawyerRegisterForm: NgForm;

  @ViewChild('resetArbitratorRegisterForm', {
    static: true
  }) resetArbitratorRegisterForm: NgForm;

  @ViewChild('resetMediatorRegisterForm', {
    static: true
  }) resetMediatorRegisterForm: NgForm;

  @ViewChild('resetNotaryRegisterForm', {
    static: true
  }) resetNotaryRegisterForm: NgForm;

  @ViewChild('resetBailiffRegisterForm', {
    static: true
  }) resetBailiffRegisterForm: NgForm;

  @ViewChild('resetJuristRegisterForm', {
    static: true
  }) resetJuristRegisterForm: NgForm;

  @ViewChild('resetOmbudsmanRegisterForm', {
    static: true
  }) resetOmbudsmanRegisterForm: NgForm;

  editFormGroup: FormGroup;
  resetPasswordFormGroup: FormGroup;
  registerLawyerForm: FormGroup;
  registerArbitratorForm: FormGroup;
  registerMediatorForm: FormGroup;
  registerNotaryForm: FormGroup;
  registerBailiffForm: FormGroup;
  registerJuristForm: FormGroup;
  registerOmbudsmanForm: FormGroup;

  profile: boolean = true;
  competency: boolean = false;

  // Properties for Professionals's Details
  lawyersCollege: string;
  propertyLaw: string;
  inheritanceLaw: string;
  commercialLaw: string;
  copyrightRelatedLaw: string;
  laborLaw: string;
  familyLaw: string;
  contractualLaw: string;
  criminalLaw: string;
  financialLaw: string;
  taxLaw: string;
  insuranceLaw: string;
  internationalPrivateLaw: string;
  internationalPublicLaw: string;
  competencies: string;
  lawBranch: string;
  regionOfOperation: string;
  registrationNumber: string;
  status: string;
  ombudsmanType: string;
  workRegion: string;

  details: any = {};

  constructor(private formBuilder: FormBuilder, private cookieService: CookieService, private data: ProfessionalService, private snackBar: SnackBarService) { }

  ngOnInit() {

    this.proId = this.cookieService.get('proId');
    this.proName = this.cookieService.get('proName');
    this.proEmail = this.cookieService.get('proEmail');
    this.proPhone = this.cookieService.get('proPhone');
    this.proType = this.cookieService.get('proType');
    this.proForeignLanguages = this.cookieService.get('proForeignLanguages');
    this.registerAgreement = this.cookieService.get('registerAgreement');

    this.getCookieByType(parseInt(this.proType));

    this.createForms();
    this.disableFields();
    this.parseDetails(parseInt(this.proType));
  }

  getCookieByType(proType: number) {

    if (proType == 0) {
      this.lawyersCollege = this.cookieService.get('lawyersCollege');
      this.propertyLaw = this.cookieService.get('propertyLaw');
      this.inheritanceLaw = this.cookieService.get('inheritanceLaw');
      this.commercialLaw = this.cookieService.get('commercialLaw');
      this.copyrightRelatedLaw = this.cookieService.get('copyrightRelatedLaw');
      this.laborLaw = this.cookieService.get('laborLaw');
      this.familyLaw = this.cookieService.get('familyLaw');
      this.contractualLaw = this.cookieService.get('contractualLaw');
      this.criminalLaw = this.cookieService.get('criminalLaw');
      this.financialLaw = this.cookieService.get('financialLaw');
      this.taxLaw = this.cookieService.get('taxLaw');
      this.insuranceLaw = this.cookieService.get('insuranceLaw');
      this.internationalPrivateLaw = this.cookieService.get('internationalPrivateLaw');
      this.internationalPublicLaw = this.cookieService.get('internationalPublicLaw');
    } else if (proType == 1) {
      this.competencies = this.cookieService.get('competencies');
    } else if (proType == 2) {
      this.lawBranch = this.cookieService.get('lawBranch');
    } else if (proType == 3) {
      this.regionOfOperation = this.cookieService.get('regionOfOperation');
      this.registrationNumber = this.cookieService.get('registrationNumber');
    } else if (proType == 4) {
      this.status = this.cookieService.get('status');
    } else if (proType == 5) {
      this.competencies = this.cookieService.get('competencies');
      this.status = this.cookieService.get('status');
    } else if (proType == 6) {
      this.ombudsmanType = this.cookieService.get('ombudsmanType');
      this.workRegion = this.cookieService.get('workRegion');
    }

  }

  parseDetails(proType: number) {

    this.editFormGroup.patchValue({
      proName: this.proName,
      phone: this.proPhone,
      email: this.proEmail,
      foreignLanguages: this.proForeignLanguages,
      // We turn the string "true" and "false" into boolean true and false, thanks to JSON.parse
      approval: JSON.parse(this.registerAgreement)
    });

    if (proType == 0) {

      this.registerLawyerForm.patchValue({
        lawyersCollege: this.lawyersCollege,
        propertyLaw: parseInt(this.propertyLaw),
        inheritanceLaw: parseInt(this.inheritanceLaw),
        commercialLaw: parseInt(this.commercialLaw),
        copyrightRelatedLaw: parseInt(this.copyrightRelatedLaw),
        laborLaw: parseInt(this.laborLaw),
        familyLaw: parseInt(this.familyLaw),
        contractualLaw: parseInt(this.contractualLaw),
        criminalLaw: parseInt(this.criminalLaw),
        financialLaw: parseInt(this.financialLaw),
        taxLaw: parseInt(this.taxLaw),
        insuranceLaw: parseInt(this.insuranceLaw),
        internationalPrivateLaw: parseInt(this.internationalPrivateLaw),
        internationalPublicLaw: parseInt(this.internationalPublicLaw)
      });

    } else if (proType == 1) {

      this.registerArbitratorForm.patchValue({
        competencies: this.competencies
      });

    } else if (proType == 2) {

      this.registerMediatorForm.patchValue({
        lawBranch: this.lawBranch
      });

    } else if (proType == 3) {

      this.registerNotaryForm.patchValue({
        regionOfOperation: this.regionOfOperation,
        registrationNumber: this.registrationNumber
      });

    } else if (proType == 4) {

      this.registerBailiffForm.patchValue({
        status: parseInt(this.status)
      });

    } else if (proType == 5) {

      this.registerJuristForm.patchValue({
        competencies: this.competencies,
        status: parseInt(this.status)
      });

    } else if (proType == 6) {

      this.registerOmbudsmanForm.patchValue({
        ombudsmanType: parseInt(this.ombudsmanType),
        workRegion: this.workRegion
      });

    }
    this.showLoader = false;
  }

  createForms() {
    this.editFormGroup = this.formBuilder.group({
      proName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      foreignLanguages: ['',],
      approval: []
    });

    this.resetPasswordFormGroup = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required]
    });

    this.registerLawyerForm = this.formBuilder.group({
      lawyersCollege: ['', Validators.required],
      propertyLaw: [false],
      inheritanceLaw: [false],
      commercialLaw: [false],
      copyrightRelatedLaw: [false],
      laborLaw: [false],
      familyLaw: [false],
      contractualLaw: [false],
      criminalLaw: [false],
      financialLaw: [false],
      taxLaw: [false],
      insuranceLaw: [false],
      internationalPrivateLaw: [false],
      internationalPublicLaw: [false]
    });

    this.registerArbitratorForm = this.formBuilder.group({
      competencies: ['', Validators.required]
    });

    this.registerMediatorForm = this.formBuilder.group({
      lawBranch: ['', Validators.required]
    });

    this.registerNotaryForm = this.formBuilder.group({
      regionOfOperation: ['', Validators.required],
      registrationNumber: ['', Validators.required]
    });

    this.registerBailiffForm = this.formBuilder.group({
      status: [],
    });

    this.registerJuristForm = this.formBuilder.group({
      competencies: [''],
      status: [],
    });

    this.registerOmbudsmanForm = this.formBuilder.group({
      workRegion: [''],
      ombudsmanType: [],
    });
  }


  onEditProfileInfo() {
    this.showLoader = true;
    console.log('this.editFormGroup.controls', this.editFormGroup.controls)
    if (this.editFormGroup.controls.proName.valid && this.editFormGroup.controls.phone.valid) {

      let proEditObj = {
        id: parseInt(this.proId),
        name: this.editFormGroup.controls.proName.value,
        phone: this.editFormGroup.controls.phone.value,
        foreignLanguages: this.editFormGroup.controls.foreignLanguages.value,
        registerAgreement: this.editFormGroup.controls.approval.value,

        type: parseInt(this.proType),

        details: this.returnObjByType(),
      }

      this.data.editProfileInfo(proEditObj).subscribe(
        data => {
          console.log('respons data', data)
          this.cookieService.set('proId', data['id']);
          this.cookieService.set('proName', data['name']);
          this.cookieService.set('proPhone', data['phone']);
          this.cookieService.set('proType', data['type']);
          this.cookieService.set('proForeignLanguages', data['foreignLanguages']);
          this.cookieService.set('registerAgreement', data['registerAgreement']);

          this.cookieByType(data);

          console.log('All cookies', this.cookieService.getAll())
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно променихте информацията за вашия профил.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  returnObjByType() {
    if (this.proType == '0') {

      let lawyerObj = {
        lawyersCollege: this.registerLawyerForm.controls.lawyersCollege.value,
        propertyLaw: this.registerLawyerForm.controls.propertyLaw.value,
        inheritanceLaw: this.registerLawyerForm.controls.inheritanceLaw.value,
        commercialLaw: this.registerLawyerForm.controls.commercialLaw.value,
        copyrightRelatedLaw: this.registerLawyerForm.controls.copyrightRelatedLaw.value,
        laborLaw: this.registerLawyerForm.controls.laborLaw.value,
        familyLaw: this.registerLawyerForm.controls.familyLaw.value,
        contractualLaw: this.registerLawyerForm.controls.contractualLaw.value,
        criminalLaw: this.registerLawyerForm.controls.criminalLaw.value,
        financialLaw: this.registerLawyerForm.controls.financialLaw.value,
        taxLaw: this.registerLawyerForm.controls.taxLaw.value,
        insuranceLaw: this.registerLawyerForm.controls.insuranceLaw.value,
        internationalPrivateLaw: this.registerLawyerForm.controls.internationalPrivateLaw.value,
        internationalPublicLaw: this.registerLawyerForm.controls.internationalPublicLaw.value
      }

      return lawyerObj;

    } else if (this.proType == '1') {

      let arbitratorObj = {
        competencies: this.registerArbitratorForm.controls.competencies.value
      }

      return arbitratorObj;

    } else if (this.proType == '2') {

      let mediatorObj = {
        lawBranch: this.registerMediatorForm.controls.lawBranch.value
      }

      return mediatorObj;

    } else if (this.proType == '3') {

      let notaryObj = {
        regionOfOperation: this.registerNotaryForm.controls.regionOfOperation.value,
        registrationNumber: this.registerNotaryForm.controls.registrationNumber.value
      }

      return notaryObj;

    } else if (this.proType == '4') {

      let bailiffObj = {
        status: this.registerBailiffForm.controls.status.value
      }

      return bailiffObj;

    } else if (this.proType == '5') {

      let juristObj = {
        competencies: this.registerJuristForm.controls.competencies.value,
        status: this.registerJuristForm.controls.status.value
      }

      return juristObj;

    } else if (this.proType == '6') {

      let ombudsmanObj = {
        ombudsmanType: this.registerOmbudsmanForm.controls.ombudsmanType.value,
        workRegion: this.registerOmbudsmanForm.controls.workRegion.value
      }

      return ombudsmanObj;

    }
  }

  cookieByType(data: any) {
    if (data.type == 0) {
      this.cookieService.set('lawyersCollege', data.details.lawyersCollege);
      this.cookieService.set('propertyLaw', data.details.propertyLaw);
      this.cookieService.set('inheritanceLaw', data.details.inheritanceLaw);
      this.cookieService.set('commercialLaw', data.details.commercialLaw);
      this.cookieService.set('copyrightRelatedLaw', data.details.copyrightRelatedLaw);
      this.cookieService.set('laborLaw', data.details.laborLaw);
      this.cookieService.set('familyLaw', data.details.familyLaw);
      this.cookieService.set('contractualLaw', data.details.contractualLaw);
      this.cookieService.set('criminalLaw', data.details.criminalLaw);
      this.cookieService.set('financialLaw', data.details.financialLaw);
      this.cookieService.set('taxLaw', data.details.taxLaw);
      this.cookieService.set('insuranceLaw', data.details.insuranceLaw);
      this.cookieService.set('internationalPrivateLaw', data.details.internationalPrivateLaw);
      this.cookieService.set('internationalPublicLaw', data.details.internationalPublicLaw);
    } else if (data.type == 1) {
      this.cookieService.set('competencies', data.details.competencies);
    } else if (data.type == 2) {
      this.cookieService.set('lawBranch', data.details.lawBranch);
    } else if (data.type == 3) {
      this.cookieService.set('regionOfOperation', data.details.regionOfOperation);
      this.cookieService.set('registrationNumber', data.details.registrationNumber);
    } else if (data.type == 4) {
      this.cookieService.set('status', data.details.status);
    } else if (data.type == 5) {
      this.cookieService.set('competencies', data.details.competencies);
      this.cookieService.set('status', data.details.status);
    } else if (data.type == 6) {
      this.cookieService.set('ombudsmanType', data.details.ombudsmanType);
      this.cookieService.set('workRegion', data.details.workRegion);
    }
  }

  onResetPassword() {

    this.showLoader = true;

    if (this.resetPasswordFormGroup.controls.newPassword.valid && this.resetPasswordFormGroup.controls.oldPassword.valid) {

      let changePassObj = {
        id: parseInt(this.proId),
        newPassword: this.resetPasswordFormGroup.controls.newPassword.value,
        oldPassword: this.resetPasswordFormGroup.controls.oldPassword.value
      }

      this.data.resetPassword(changePassObj).subscribe(
        data => {
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно променихте паролата си.', 'Затвори');
        },
        error => {
          if (error.status == 401) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Грешно сте въвели старата парола', 'Затвори');
          } else if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  goTo(where: string) {
    if (where == 'profile') {
      this.profile = true;
      this.competency = false;
    } else if (where == 'competency') {
      this.profile = false;
      this.competency = true;
    }
  }

  enableField(status: boolean) {
    if (status == false) {
      this.enableFields();
      this.enableEdit = status;
    } else {
      this.disableFields();
      this.enableEdit = status;
    }
  }

  disableFields() {
    this.editFormGroup.controls.proName.disable();
    this.editFormGroup.controls.email.disable();
    this.editFormGroup.controls.phone.disable();
    this.editFormGroup.controls.approval.disable();
    this.resetPasswordFormGroup.controls.oldPassword.disable();
    this.resetPasswordFormGroup.controls.newPassword.disable();
    this.registerLawyerForm.controls.lawyersCollege.disable();
    this.registerLawyerForm.controls.propertyLaw.disable();
    this.registerLawyerForm.controls.inheritanceLaw.disable();
    this.registerLawyerForm.controls.commercialLaw.disable();
    this.registerLawyerForm.controls.copyrightRelatedLaw.disable();
    this.registerLawyerForm.controls.laborLaw.disable();
    this.registerLawyerForm.controls.familyLaw.disable();
    this.registerLawyerForm.controls.contractualLaw.disable();
    this.registerLawyerForm.controls.criminalLaw.disable();
    this.registerLawyerForm.controls.financialLaw.disable();
    this.registerLawyerForm.controls.taxLaw.disable();
    this.registerLawyerForm.controls.insuranceLaw.disable();
    this.registerLawyerForm.controls.internationalPrivateLaw.disable();
    this.registerLawyerForm.controls.internationalPublicLaw.disable();
    this.registerArbitratorForm.controls.competencies.disable();
    this.registerMediatorForm.controls.lawBranch.disable();
    this.registerNotaryForm.controls.regionOfOperation.disable();
    this.registerNotaryForm.controls.registrationNumber.disable();
    this.registerBailiffForm.controls.status.disable();
    this.registerJuristForm.controls.competencies.disable();
    this.registerJuristForm.controls.status.disable();
    this.registerOmbudsmanForm.controls.workRegion.disable();
    this.registerOmbudsmanForm.controls.ombudsmanType.disable();
  }

  enableFields() {
    this.editFormGroup.controls.proName.enable();
    this.editFormGroup.controls.email.disable();
    this.editFormGroup.controls.phone.enable();
    this.editFormGroup.controls.approval.enable();
    this.resetPasswordFormGroup.controls.oldPassword.enable();
    this.resetPasswordFormGroup.controls.newPassword.enable();
    this.registerLawyerForm.controls.lawyersCollege.enable();
    this.registerLawyerForm.controls.propertyLaw.enable();
    this.registerLawyerForm.controls.inheritanceLaw.enable();
    this.registerLawyerForm.controls.commercialLaw.enable();
    this.registerLawyerForm.controls.copyrightRelatedLaw.enable();
    this.registerLawyerForm.controls.laborLaw.enable();
    this.registerLawyerForm.controls.familyLaw.enable();
    this.registerLawyerForm.controls.contractualLaw.enable();
    this.registerLawyerForm.controls.criminalLaw.enable();
    this.registerLawyerForm.controls.financialLaw.enable();
    this.registerLawyerForm.controls.taxLaw.enable();
    this.registerLawyerForm.controls.insuranceLaw.enable();
    this.registerLawyerForm.controls.internationalPrivateLaw.enable();
    this.registerLawyerForm.controls.internationalPublicLaw.enable();
    this.registerArbitratorForm.controls.competencies.enable();
    this.registerMediatorForm.controls.lawBranch.enable();
    this.registerNotaryForm.controls.regionOfOperation.enable();
    this.registerNotaryForm.controls.registrationNumber.enable();
    this.registerBailiffForm.controls.status.enable();
    this.registerJuristForm.controls.competencies.enable();
    this.registerJuristForm.controls.status.enable();
    this.registerOmbudsmanForm.controls.workRegion.enable();
    this.registerOmbudsmanForm.controls.ombudsmanType.enable();
  }
}

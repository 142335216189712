import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChangePasswordService } from '../services/change-password.service';
import { SnackBarService } from '../services/snackbar.service';
// import { tokenUserTypes } from '../services/constants.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('resetChangePasswordForm', {
    static: true
  }) resetChangePasswordForm: NgForm;

  changePasswordFormGroup: FormGroup;

  showLoader: boolean = false;
  checkSimilarity: boolean = false;

  private routeSub: Subscription;

  visibility: string = 'visibility_off';
  inputType: string = 'password';
  visibilityRepeat: string = 'visibility_off';
  inputTypeRepeat: string = 'password';

  userType: any;
  tkn: any;

  // fileType: number = tokenUserTypes.type[0].value;

  constructor(private formBuilder: FormBuilder, private data: ChangePasswordService, private route: ActivatedRoute, private snackBar: SnackBarService) { }

  ngOnInit() {
    this.createForms();

    this.routeSub = this.route.params.subscribe(params => {
      this.userType = params['userType'];
      this.tkn = params['tkn'];
      console.log('dfdddddd', typeof this.userType, typeof this.tkn)
    });
  }

  createForms() {

    this.changePasswordFormGroup = this.formBuilder.group({
      password: ['', Validators.required],
      repeatPassword: ['', Validators.required],
    });

  }

  onChangePassword() {

    this.showLoader = true;

    if (this.changePasswordFormGroup.controls.password.value == this.changePasswordFormGroup.controls.repeatPassword.value) {

      let changePasswordObj = {
        uid: this.tkn,
        tokenType: this.userType,
        password: this.changePasswordFormGroup.controls.password.value
      }

      this.data.changePassword(changePasswordObj).subscribe(
        data => {
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте парола.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.checkSimilarity = true;
    }
  }

  checkForSimilarity() { }

  passwordVisibility() {
    if (this.visibility == 'visibility') {
      this.visibility = 'visibility_off';
      this.inputType = 'password';
    } else {
      this.visibility = 'visibility';
      this.inputType = 'text';
    }
  }

  passwordVisibilityRepeat() {
    if (this.visibilityRepeat == 'visibility') {
      this.visibilityRepeat = 'visibility_off';
      this.inputTypeRepeat = 'password';
    } else {
      this.visibilityRepeat = 'visibility';
      this.inputTypeRepeat = 'text';
    }
  }

  resetValidation() {
    this.checkSimilarity = false;
  }

}

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlgorithmsService } from 'src/app/services/algorithms.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SnackBarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-admin-algorithms',
  templateUrl: './admin-algorithms.component.html',
  styleUrls: ['./admin-algorithms.component.css']
})
export class AdminAlgorithmsComponent implements OnInit {

  algorithmType: number = 1;

  choicesFTAFormGroup: FormGroup;
  choicesSTAFormGroup: FormGroup;
  choicesTTAFormGroup: FormGroup;

  @ViewChild('resetChoicesFTAForm', {
    static: false
  }) resetChoicesFTAForm: NgForm;

  @ViewChild('resetChoicesSTAForm', {
    static: false
  }) resetChoicesSTAForm: NgForm;

  @ViewChild('resetChoicesTTAForm', {
    static: false
  }) resetChoicesTTAForm: NgForm;

  stepOneChoices: any = [{
    id: 0,
    number: 1,
    choice: 0,
    explanation: 0,
    answers: 0
  }];

  stepTwoChoices: any = [{
    id: 0,
    number: 1,
    choice: 0,
    explanation: 0,
    answers: 0
  }];

  answers: any = [{
    id: 0,
    number: 1,
    answer: 0,
    explanation: 0
  }];

  relations: any = [{
    id: 0,
    answerNumber: 1,
    stepOneChoiceNumber: 0,
    stepTwoChoiceNumber: 0
  }];

  answersTwo: any = [{
    id: 0,
    number: 1,
    answer: 0,
    explanation: 0
  }];

  relationsTwo: any = [{
    id: 0,
    answerNumber: 1,
    stepOneChoiceNumber: 0,
    stepTwoChoiceNumber: 0
  }];

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;

  algorithms: any;

  showLoader: boolean = true;

  rowsAlgorithms = <any>[];
  columnsAlgorithms = <any>[];

  @Input() prop!: number;
  loadingIndicator: boolean;
  reorderable = true;
  swapColumns = true;
  SelectionType = SelectionType;

  @ViewChild('actionTemplate', { static: false }) actionTemplate: TemplateRef<any>;
  @ViewChild('typeTemplate', { static: false }) typeTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
  @ViewChild('headerActionTemplate', { static: false }) headerActionTemplate: TemplateRef<any>;

  TableSettings = {
    messages: {
      emptyMessage: `
        <div class="padding-one" style>
        <span>Нямате заявки, за показване.</span>
        </div>
        `
    }
  }
  messages = this.TableSettings.messages;
  allRelations: Array<any> = [];
  //marginBottomSize: number;

  constructor(private formBuilder: FormBuilder, private data: AlgorithmsService, private snackBar: SnackBarService) { }

  ngAfterViewInit(): void {
    this.makeTable();
  }

  ngOnInit() {
    this.showByAlgorithmType(this.algorithmType);
    this.makeTable();
    this.onGetAllAlgorithms(this.offset, this.limit);
  }

  createFTAForms() {

    this.choicesFTAFormGroup = this.formBuilder.group({
      id: [0],
      type: [0],
      title: ['', Validators.required],
      explanation: ['', Validators.required],
      stepOne: ['', Validators.required],
      stepOneChoices: new FormArray([this.createChoice()]),
      stepTwo: ['', Validators.required],
      stepTwoChoices: [[]],
      answers: new FormArray([this.createAnswers()]),
      relations: new FormArray([this.createRelation()])
    });

  }

  createSTAForms() {

    this.choicesSTAFormGroup = this.formBuilder.group({
      id: [0],
      type: [1],
      title: ['', Validators.required],
      explanation: ['', Validators.required],
      stepOne: ['', Validators.required],
      stepOneChoices: new FormArray([this.createChoice()]),
      stepTwo: ['', Validators.required],
      stepTwoChoices: new FormArray([this.createChoice()]),
      answers: new FormArray([this.createAnswers()]),
      answersTwo: new FormArray([this.createAnswers()]),
      relations: new FormArray([this.createRelation()]),
      relationsTwo: new FormArray([this.createRelation()])
    });

  }

  createTTAForms() {

    this.choicesTTAFormGroup = this.formBuilder.group({
      id: [0],
      type: [2],
      title: ['', Validators.required],
      explanation: ['', Validators.required],
      stepOne: ['', Validators.required],
      stepOneChoices: new FormArray([this.createChoice()]),
      stepTwo: ['', Validators.required],
      stepTwoChoices: new FormArray([this.createChoice()]),
      answers: new FormArray([this.createAnswers()]),
      relations: new FormArray([this.createRelation()]),
      relationsTwo: new FormArray([])
    });

    // All Relations for View
    this.showAllRelationsForTTAInView();
  }

  createChoice() {
    return this.formBuilder.group({
      id: [0],
      number: [1],
      choice: ['', Validators.required],
      explanation: ['', Validators.required]
    });
  }

  createAnswers() {
    return this.formBuilder.group({
      id: [0],
      number: [1, Validators.required],
      answer: ['', Validators.required],
      explanation: ['']
    });
  }

  createRelation() {
    return this.formBuilder.group({
      id: [0],
      answerNumber: [1, Validators.required],
      stepOneChoiceNumber: [0, Validators.required],
      stepTwoChoiceNumber: [0, Validators.required]
    });
  }

  makeTable() {

    this.columnsAlgorithms = [
      { name: 'Заглавие', prop: "title", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Описание', prop: "explanation", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Вид Алгоритъм', prop: "type", width: 100, value: 1, cellTemplate: this.typeTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

  }

  // First Type

  onAddFTA() {

    this.showLoader = true;


    if (!this.choicesFTAFormGroup.invalid) {

      if (!this.choicesFTAFormGroup.controls.id.value) {
        this.setNumbersAndRelationsFTA(); console.log('firstfirst1', this.choicesFTAFormGroup.controls.id.value)
        this.data.addFTA(this.choicesFTAFormGroup.value).subscribe(
          data => {
            console.log('data', data);
            this.resetFTAForm();
            this.onGetAllAlgorithms(this.offset, this.limit);
            this.snackBar.openSnackBar('Успешно записахте алгоритъм.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          });
      } else {
        this.setNumbersAndRelationsFTA(); console.log('firstfirst3', this.choicesFTAFormGroup.controls.id.value)
        this.data.editFTA(this.choicesFTAFormGroup.value).subscribe(
          data => {
            console.log('data', data);
            this.resetFTAForm();
            this.onGetAllAlgorithms(this.offset, this.limit);
            this.snackBar.openSnackBar('Успешно записахте алгоритъм.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          });
      }

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  addFTAChoice(): void {
    this.stepOneChoices = this.choicesFTAFormGroup.get('stepOneChoices') as FormArray;
    this.stepOneChoices.push(this.createChoice());
    this.answers = this.choicesFTAFormGroup.get('answers') as FormArray;
    this.answers.push(this.createAnswers());
    this.relations = this.choicesFTAFormGroup.get('relations') as FormArray;
    this.relations.push(this.createRelation());
  }

  setNumbersAndRelationsFTA() {
    for (let i = 0; i < this.choicesFTAFormGroup.controls.answers.value.length; i++) {
      this.choicesFTAFormGroup.controls.stepOneChoices.value[i].number = i + 1;
      this.choicesFTAFormGroup.controls.answers.value[i].number = i + 1;
      this.choicesFTAFormGroup.controls.relations.value[i].answerNumber = i + 1;
      this.choicesFTAFormGroup.controls.relations.value[i].stepOneChoiceNumber = i + 1;
    }
  }

  deleteFTAChoice(choiceIndex: number) {
    this.stepOneChoices.removeAt(choiceIndex);
    this.answers.removeAt(choiceIndex);
    this.relations.removeAt(choiceIndex);
  }

  resetFTAForm() {
    this.resetChoicesFTAForm.resetForm();
    this.createFTAForms();
    this.stepOneChoices.push(this.createChoice());
    this.answers.push(this.createAnswers());
    this.relations.push(this.createRelation());
  }

  // // // // //

  // Second Type

  onAddSTA() {

    this.showLoader = true;

    let STAObj = {
      id: this.choicesSTAFormGroup.controls.id.value,
      type: this.choicesSTAFormGroup.controls.type.value,
      title: this.choicesSTAFormGroup.controls.title.value,
      explanation: this.choicesSTAFormGroup.controls.explanation.value,
      stepOne: this.choicesSTAFormGroup.controls.stepOne.value,
      stepOneChoices: this.choicesSTAFormGroup.controls.stepOneChoices.value,
      stepTwo: this.choicesSTAFormGroup.controls.stepTwo.value,
      stepTwoChoices: this.choicesSTAFormGroup.controls.stepTwoChoices.value,
      answers: this.choicesSTAFormGroup.controls.answers.value.concat(this.choicesSTAFormGroup.controls.answersTwo.value),
      relations: this.choicesSTAFormGroup.controls.relations.value.concat(this.choicesSTAFormGroup.controls.relationsTwo.value)
    }
console.log('STAObj', STAObj)
    this.setNumbersAndRelationsSTA(STAObj);

    if (!this.choicesSTAFormGroup.invalid) {

      if (!this.choicesSTAFormGroup.controls.id.value) {
        this.data.addFTA(STAObj).subscribe(
          data => {
            console.log('data', data);
            this.resetSTAForm();
            this.onGetAllAlgorithms(this.offset, this.limit);
            this.snackBar.openSnackBar('Успешно записахте алгоритъм.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          });
      } else {
        this.data.editFTA(STAObj).subscribe(
          data => {
            console.log('data', data);
            this.resetSTAForm();
            this.onGetAllAlgorithms(this.offset, this.limit);
            this.snackBar.openSnackBar('Успешно записахте алгоритъм.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          });
      }

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  addSTAChoiceOne(): void {
    this.stepOneChoices = this.choicesSTAFormGroup.get('stepOneChoices') as FormArray;
    this.stepOneChoices.push(this.createChoice());
    this.answers = this.choicesSTAFormGroup.get('answers') as FormArray;
    this.answers.push(this.createAnswers());
    this.relations = this.choicesSTAFormGroup.get('relations') as FormArray;
    this.relations.push(this.createRelation());
  }

  addSTAChoiceTwo(): void {
    this.stepTwoChoices = this.choicesSTAFormGroup.get('stepTwoChoices') as FormArray;
    this.stepTwoChoices.push(this.createChoice());
    this.answersTwo = this.choicesSTAFormGroup.get('answersTwo') as FormArray;
    this.answersTwo.push(this.createAnswers());
    this.relationsTwo = this.choicesSTAFormGroup.get('relationsTwo') as FormArray;
    this.relationsTwo.push(this.createRelation());
  }

  setNumbersAndRelationsSTA(STAObj) {

    for (let i = 0; i < STAObj.stepOneChoices.length; i++) {
      STAObj.stepOneChoices[i].number = i + 1;
      STAObj.relations[i].stepOneChoiceNumber = i + 1;
    }

    for (let i = 0; i < STAObj.stepTwoChoices.length; i++) {
      STAObj.stepTwoChoices[i].number = i + 1;
      STAObj.relations[i + STAObj.stepOneChoices.length].stepTwoChoiceNumber = i + 1;
      STAObj.relations[i + STAObj.stepOneChoices.length].stepOneChoiceNumber = 0;
    }

    for (let i = 0; i < STAObj.answers.length; i++) {
      STAObj.answers[i].number = i + 1;
    }

    for (let i = 0; i < STAObj.relations.length; i++) {
      STAObj.relations[i].answerNumber = i + 1;
    }

  }

  deleteSTAChoiceOne(choiceIndex: number) {
    this.stepOneChoices.removeAt(choiceIndex);
    this.answers.removeAt(choiceIndex);
    this.relations.removeAt(choiceIndex);
  }

  deleteSTAChoiceTwo(choiceIndex: number) {
    this.stepTwoChoices.removeAt(choiceIndex);
    this.answersTwo.removeAt(choiceIndex);
    this.relationsTwo.removeAt(choiceIndex);
  }

  resetSTAForm() {
    this.resetChoicesSTAForm.resetForm();
    // this.stepOneChoices.clear();
    // this.stepTwoChoices.clear();
    // this.answers.clear();
    // this.answersTwo.clear();
    // this.relations.clear();
    // this.relationsTwo.clear();
    this.createSTAForms();
    this.stepOneChoices.push(this.createChoice());
    this.stepTwoChoices.push(this.createChoice());
    this.answers.push(this.createAnswers());
    this.answersTwo.push(this.createAnswers());
    this.relations.push(this.createRelation());
    this.relationsTwo.push(this.createRelation());
  }

  // // // // //

  // Third Type

  onAddTTA() {
console.log('this.choicesTTAFormGroup', this.choicesTTAFormGroup)
    this.showLoader = true;

    let TTAObj = {
      id: this.choicesTTAFormGroup.controls.id.value,
      type: this.choicesTTAFormGroup.controls.type.value,
      title: this.choicesTTAFormGroup.controls.title.value,
      explanation: this.choicesTTAFormGroup.controls.explanation.value,
      stepOne: this.choicesTTAFormGroup.controls.stepOne.value,
      stepOneChoices: this.choicesTTAFormGroup.controls.stepOneChoices.value,
      stepTwo: this.choicesTTAFormGroup.controls.stepTwo.value,
      stepTwoChoices: this.choicesTTAFormGroup.controls.stepTwoChoices.value,
      answers: this.choicesTTAFormGroup.controls.answers.value,
      relations: this.choicesTTAFormGroup.controls.relations.value.concat(this.choicesTTAFormGroup.controls.relationsTwo.value)
    }

    this.setNumbersAndRelationsTTA(TTAObj);

    if (!this.choicesTTAFormGroup.invalid) {

      if (!this.choicesTTAFormGroup.controls.id.value) {
        this.data.addFTA(TTAObj).subscribe(
          data => {
            console.log('data', data);
            this.resetTTAForm();
            this.onGetAllAlgorithms(this.offset, this.limit);
            this.snackBar.openSnackBar('Успешно записахте алгоритъм.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          });
      } else {
        this.data.editFTA(TTAObj).subscribe(
          data => {
            console.log('data', data);
            this.resetTTAForm();
            this.onGetAllAlgorithms(this.offset, this.limit);
            this.snackBar.openSnackBar('Успешно записахте алгоритъм.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          });
      }

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  addTTAChoiceOne(): void {
    this.stepOneChoices = this.choicesTTAFormGroup.get('stepOneChoices') as FormArray;
    this.stepOneChoices.push(this.createChoice());

    // Answers
    for (let i = 0; i < this.stepTwoChoices.length; i++) {
      this.answers = this.choicesTTAFormGroup.get('answers') as FormArray;
      this.answers.push(this.createAnswers());
    }

    // Relations
    for (let i = 0; i < this.stepTwoChoices.length; i++) {
      this.relations = this.choicesTTAFormGroup.get('relations') as FormArray;
      this.relations.push(this.createRelation());
    }

    // All Relations for View
    this.showAllRelationsForTTAInView();
  }

  addTTAChoiceTwo(): void {
    this.stepTwoChoices = this.choicesTTAFormGroup.get('stepTwoChoices') as FormArray;
    this.stepTwoChoices.push(this.createChoice());

    // Answers
    var answersCount = this.stepOneChoices.length * this.stepTwoChoices.length;
    var answersCount = answersCount - this.answers.length;
    var stepTwoChoicesLength = this.stepTwoChoices.length - 1;

    for (let i = 0; i < answersCount; i++) {
      this.answers = this.choicesTTAFormGroup.get('answers') as FormArray;
      this.answers.insert(stepTwoChoicesLength, this.createAnswers());
      stepTwoChoicesLength = stepTwoChoicesLength + this.stepTwoChoices.length;
    }

    //this.marginBottomSize = 210 * (this.stepTwoChoices.length - 1);

    // Relations
    for (let i = 0; i < this.stepOneChoices.length; i++) {
      this.relations = this.choicesTTAFormGroup.get('relations') as FormArray;
      this.relations.push(this.createRelation());
    }

    // All Relations for View
    this.showAllRelationsForTTAInView();
  }

  setNumbersAndRelationsTTA(TTAObj) {

    for (let i = 0; i < TTAObj.stepOneChoices.length; i++) {
      TTAObj.stepOneChoices[i].number = i + 1;
    }

    for (let i = 0; i < TTAObj.stepTwoChoices.length; i++) {
      TTAObj.stepTwoChoices[i].number = i + 1;
    }

    for (let i = 0; i < TTAObj.answers.length; i++) {
      TTAObj.answers[i].number = i + 1;
    }

    // Relations
    for (let i = 0; i < TTAObj.relations.length; i++) {
      TTAObj.relations[i].answerNumber = i + 1;
    }

    var relationsCount = 0;

    for (let i = 1; i <= TTAObj.stepOneChoices.length; i++) {
      for (let j = 1; j <= TTAObj.stepTwoChoices.length; j++) {
        TTAObj.relations[relationsCount].stepOneChoiceNumber = i;
        TTAObj.relations[relationsCount].stepTwoChoiceNumber = j;
        relationsCount++
      }
    }
    console.log('test', TTAObj.relations)
  }

  deleteTTAChoiceOne(choiceIndex: number) {
    this.stepOneChoices.removeAt(choiceIndex);
    // this.relations.removeAt(choiceIndex);

    var answerIndex = choiceIndex * this.stepTwoChoices.length;

    for (let i = 1; i <= this.stepTwoChoices.length; i++) {
      this.answers.removeAt(answerIndex);
      this.relations.removeAt(answerIndex);
    }

    // All Relations for View
    this.showAllRelationsForTTAInView();
  }

  deleteTTAChoiceTwo(choiceIndex: number) {
    var loop = (this.stepOneChoices.length * this.stepTwoChoices.length) / this.stepTwoChoices.length
    this.stepTwoChoices.removeAt(choiceIndex);
    this.relations.removeAt(choiceIndex);
    this.answers.removeAt(choiceIndex);

    var choiceIndexForFor = choiceIndex + this.stepTwoChoices.length;

    for (let i = 1; i <= loop; i++) {
      this.answers.removeAt(choiceIndexForFor);
      this.relations.removeAt(choiceIndexForFor);
      choiceIndexForFor = choiceIndexForFor + this.stepTwoChoices.length;
    }

    //this.marginBottomSize = 210 * (this.stepTwoChoices.length - 1);

    // All Relations for View
    this.showAllRelationsForTTAInView();
  }

  resetTTAForm() {
    this.resetChoicesTTAForm.resetForm();
    this.createTTAForms();
    this.resetTTAProperty();
    this.stepOneChoices.push(this.createChoice());
    this.stepTwoChoices.push(this.createChoice());
    this.answers.push(this.createAnswers());
    this.relations.push(this.createRelation());
    //this.marginBottomSize = 0;
  }

  resetTTAProperty() {

    this.stepOneChoices = [{
      id: 0,
      number: 1,
      choice: 0,
      explanation: 0,
      answers: 0
    }];

    this.stepTwoChoices = [{
      id: 0,
      number: 1,
      choice: 0,
      explanation: 0,
      answers: 0
    }];

    this.answers = [{
      id: 0,
      number: 1,
      answer: 0,
      explanation: 0
    }];

    this.relations = [{
      id: 0,
      answerNumber: 1,
      stepOneChoiceNumber: 0,
      stepTwoChoiceNumber: 0
    }];

    this.answersTwo = [{
      id: 0,
      number: 1,
      answer: 0,
      explanation: 0
    }];

    this.relationsTwo = [{
      id: 0,
      answerNumber: 1,
      stepOneChoiceNumber: 0,
      stepTwoChoiceNumber: 0
    }];
  }
  // // // // //

  onGetAllAlgorithms(offset: number, limit: number) {

    this.data.getAllAlgorithms(this.offset, this.limit).subscribe(
      data => {

        this.totalResult = data.totalResult;
        
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }
        
        this.rowsAlgorithms = data.resultList;
        // this.showLoader = false;
        console.log('data', data);
      }
    );

  }

  editAlgorithm(row) {
    this.onGetAlgorithmById(row.id);
  }

  onGetAlgorithmById(algorithmId: any) {

    this.data.getAlgorithmById(algorithmId).subscribe(
      data => {

        console.log('data', data);

        this.algorithmType = data.type + 1;

        if (data.type == 0) {

          this.showByAlgorithmType(this.algorithmType);

          for (let i = 1; i < data.answers.length; i++) {
            this.addFTAChoice();
          }

          this.choicesFTAFormGroup.patchValue({
            id: data.id,
            type: data.type,
            title: data.title,
            explanation: data.explanation,
            stepOne: data.stepOne,
            stepOneChoices: data.stepOneChoices,
            stepTwo: data.stepTwo,
            stepTwoChoices: data.stepTwoChoices,
            answers: data.answers,
            relations: data.relations
          });

        } else if (data.type == 1) {

          this.showByAlgorithmType(this.algorithmType);

          for (let i = 1; i < data.stepOneChoices.length; i++) {
            this.addSTAChoiceOne();
          }

          for (let i = 1; i < data.stepTwoChoices.length; i++) {
            this.addSTAChoiceTwo();
          }

          this.choicesSTAFormGroup.patchValue({
            id: data.id,
            type: data.type,
            title: data.title,
            explanation: data.explanation,
            stepOne: data.stepOne,
            stepOneChoices: data.stepOneChoices,
            stepTwo: data.stepTwo,
            stepTwoChoices: data.stepTwoChoices,
            answers: this.chunkArray(data.answers, 2)[0],
            answersTwo: this.chunkArray(data.answers, 2)[1],
            relations: this.chunkArray(data.relations, 2)[0],
            relationsTwo: this.chunkArray(data.relations, 2)[1]
          });

        } else if (data.type == 2) {

          this.showByAlgorithmType(this.algorithmType);

          for (let i = 1; i < data.stepOneChoices.length; i++) {
            this.addTTAChoiceOne();
          }

          for (let i = 1; i < data.stepTwoChoices.length; i++) {
            this.addTTAChoiceTwo();
          }

          this.choicesTTAFormGroup.patchValue({
            id: data.id,
            type: data.type,
            title: data.title,
            explanation: data.explanation,
            stepOne: data.stepOne,
            stepOneChoices: data.stepOneChoices,
            stepTwo: data.stepTwo,
            stepTwoChoices: data.stepTwoChoices,
            answers: data.answers,
            relations: data.relations,
            relationsTwo: data.relations,
          });

        }

        // this.showLoader = false;
      }
    );

  }

  showAllRelationsForTTAInView(): void {
    this.allRelations = []
    this.allRelations = this.choicesTTAFormGroup.controls.relations.value.concat(this.choicesTTAFormGroup.controls.relationsTwo.value);

    var relationsCount = 0;
    for (let i = 1; i <= this.choicesTTAFormGroup.controls.stepOneChoices.value.length; i++) {
      for (let j = 1; j <= this.choicesTTAFormGroup.controls.stepTwoChoices.value.length; j++) {
        this.allRelations[relationsCount].stepOneChoiceNumber = i;
        this.allRelations[relationsCount].stepTwoChoiceNumber = j;
        relationsCount++
      }
    }
  }

  chunkArray(arr, n) {
    var chunkLength = Math.max(arr.length / n, 1);
    var chunks = [];
    for (var i = 0; i < n; i++) {
      if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
  }

  algorithmsPageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetAllAlgorithms(this.offset, this.limit);
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAllAlgorithms(this.offset, this.limit);
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

  showByAlgorithmType(type: number) {
    switch (type) {
      case 1:
        this.algorithmType = 1;
        this.createFTAForms();
        break;
      case 2:
        this.algorithmType = 2;
        this.createSTAForms();
        break;
      case 3:
        this.algorithmType = 3;
        //this.marginBottomSize = 0;
        this.createTTAForms();
        this.resetTTAProperty();
        break;
      default:
        this.algorithmType = 0;
        break;
    }
  }

}

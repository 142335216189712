import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DialogRedirectComponent } from '../dialog-redirect/dialog-redirect.component';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { HostListener } from "@angular/core";
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isUserLogged: boolean;
  isProfessionalLogged: boolean;
  proId: string;
  userId: string;
  openClose: boolean;
  screenHeight: number;
  screenWidth: number;
  mobileMenu: boolean;
  width: number;

  constructor(private router: Router, private cookieService: CookieService, private dialog: MatDialog, private snackBar: SnackBarService) { }

  @HostListener('window:resize', ['$event'])
  changeMenu(event) {
    event.target.innerWidth;
    // console.log("TEST", event.target.innerWidth);
    // console.log("TEST 111+---", window.innerWidth);
    this.width = event.target.innerWidth;
    let to = document.getElementById("two");
    to.style.display = 'none';
    
    if (this.width <= 1254) {
      document.getElementById("myMenu").style.display = "none";
    } else if (this.width >= 1255) {
      document.getElementById("myMenu").style.display = "flex";
    }

    if (this.width < 1255) {
      document.getElementById("mobileMenu").style.visibility = "initial";
    } else {
      document.getElementById("mobileMenu").style.visibility = "hidden";
    }
  }

  ngOnInit() {
    // this.cookieService.set( 'userId', '1');
    // this.cookieService.set( 'professionalId', '2');
    this.userId = this.cookieService.get('userId');
    this.proId = this.cookieService.get('proId');

    if (!this.userId && !this.proId) {
      this.cookieService.deleteAll();
    }
    this.checkWidth();
  }


  openNav () {
    var o = document.getElementById("one");
    var to = document.getElementById("two");
    this.openClose = true;
    document.getElementById("myMenu").style.display = "block";
    o.style.display = 'none';
    to.style.display = '';
  }

  closeNav () {
    // this.width = window.innerWidth;
    if (this.width <= 1254) {
      var o = document.getElementById("one");
    var to = document.getElementById("two");
    document.getElementById("myMenu").style.display = "none";
    o.style.display = '';
    to.style.display = 'none';
    } else {
      console.log("FULL MENU!!!")
    }
    
  }

  checkWidth() { //for now we use this for init function!!!
    this.width = window.innerWidth;
    let to = document.getElementById("two");
    to.style.display = 'none';
    
    if (this.width <= 1254) {
      document.getElementById("myMenu").style.display = "none";
      this.openClose = false;
    } else if (this.width >= 1255) {
      document.getElementById("myMenu").style.display = "flex";
    }

    if (this.width < 1255) {
      document.getElementById("mobileMenu").style.visibility = "initial";
    } else {
      document.getElementById("mobileMenu").style.visibility = "hidden";
    }
  }

  goToLoginRegOrInfo() {

    if (!this.userId && !this.proId) {
      
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = false;
  
      dialogConfig.data = {
        // id: id,
        // title: title,
        // message: message
      };
  
      this.dialog.open(DialogRedirectComponent, dialogConfig);

    } else {
      this.router.navigate(['vatreshna-sreda']);
    }

  }

  logout() {
    this.cookieService.deleteAll();
    this.router.navigate(['']);
    this.snackBar.openSnackBar('Успешно излязохте от профила си.', 'Затвори');
  }

}

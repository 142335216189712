import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  toggleMenu: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private cookieService: CookieService) { }

  ngOnInit() {

    // This is done to monitor the status of the sidebar during page changes
    if (this.cookieService.get('sideBarToggle') == 'true') {
      this.toggleMenu = true;
      this.onToggleMenu(true);
    } else {
      this.toggleMenu = false;
      this.onToggleMenu(false);
    }

  }

  onToggleMenu(toggle) {

    if (toggle == false) {

      const sidebarWrap = document.getElementsByClassName("sidebar-wrap")[0] as HTMLElement;
      const adminSidebarWrap = document.getElementsByClassName("admin-sidebar-wrap")[0] as HTMLElement;

      sidebarWrap.style.width = "80px";
      adminSidebarWrap.style.width = "80px";

      const headerWrap = document.getElementsByClassName("header-wrap")[0] as HTMLElement;
      const contentWrap = document.getElementsByClassName("content-wrap")[0] as HTMLElement;

      headerWrap.style.paddingRight = "80px";
      contentWrap.style.left = "80px";

      this.cookieService.set('sideBarToggle', 'false');

    } else if (toggle == true) {

      const sidebarWrap = document.getElementsByClassName("sidebar-wrap")[0] as HTMLElement;
      const adminSidebarWrap = document.getElementsByClassName("admin-sidebar-wrap")[0] as HTMLElement;

      sidebarWrap.style.width = "160px";
      adminSidebarWrap.style.width = "160px";

      const headerWrap = document.getElementsByClassName("header-wrap")[0] as HTMLElement;
      const contentWrap = document.getElementsByClassName("content-wrap")[0] as HTMLElement;

      headerWrap.style.paddingRight = "160px";
      contentWrap.style.left = "160px";

      this.cookieService.set('sideBarToggle', 'true');

    }

    this.toggleMenu = !this.toggleMenu;
  }

  goTo(path: string) {
    // "relativeTo: this.activatedRoute" is used for adding parent url in whole path
    if (this.router.url == '/admin') {
      this.router.navigate([path], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../' + path], { relativeTo: this.activatedRoute });
    }
  }
}

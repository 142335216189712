import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoviniService } from '../services/novini.service';
import { environment } from '../services/constants.service';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';
import { SeoService } from '../services/seo.service';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-novini-by-id',
  templateUrl: './novini-by-id.component.html',
  styleUrls: ['./novini-by-id.component.css']
})
export class NoviniByIdComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  private routeSub: Subscription;

  newsId: string;
  news: any;
  baseUrl: string;
  href: string;
  twitterHref: string;

  constructor(private route: ActivatedRoute, private data: NoviniService, private seo: SeoService, private meta: Meta, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.baseUrl = environment.base_url;

    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) //log the entire params object
      console.log(params['id']) //log the value of id
      this.newsId = params['id'];
    });

    this.onGetNewsById(this.newsId);

    this.seo.generateTags({
      title: 'Моето право',
      description: 'Новини от моето право!',
      image: 'http://moetopravo.bg/assets/logo.png',
      slug: 'contact-page'
    })

    this.twitterHref = 'https://twitter.com/intent/tweet?text=' + window.location.href;

    this.twitterHref = this.twitterHref.replace('#', '%23');
    
    this.href = window.location.href;
  }

  onGetNewsById(newsId: any) {

    this.data.getNewsById(newsId).subscribe(
      data => {

        this.news = data;
        console.log('response data', data)
        // this.showLoader = false;
      }
    );

  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarService } from '../services/snackbar.service';
import { UserService } from '../services/user.service';
import { ProfessionalService } from '../services/professional.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-dialog-forgotten-password',
  templateUrl: './dialog-forgotten-password.component.html',
  styleUrls: ['./dialog-forgotten-password.component.css']
})
export class DialogForgottenPasswordComponent implements OnInit {

  @ViewChild('resetforgottenPassword', {
    static: true
  }) resetforgottenPassword: NgForm;

  forgottenPassword: FormGroup;
  description: string;
  userType: any;

  showLoader: boolean;

  constructor(private dialogRef: MatDialogRef<DialogForgottenPasswordComponent>, @Inject(MAT_DIALOG_DATA) dialogData, private userData: UserService, private profiData: ProfessionalService, private adminData: AdminService, private formBuilder: FormBuilder, private snackBar: SnackBarService) {
    this.userType = dialogData.userType;
  }

  ngOnInit() {
    this.forgottenPassword = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  sendEmail() {
    if (this.forgottenPassword.valid) {

      let emailObj = {
        email: this.forgottenPassword.controls.email.value
      }

      if (this.userType == 'user') {
        this.userData.forgottenPassword(emailObj).subscribe(
          data => {
            console.log('respons data', data)
            this.snackBar.openSnackBar('Успешно дадохте заявка, за смяна на парола, ще получите съобщение в Електронната поща, за потвърждение.', 'Затвори');
            this.dialogRef.close();
          }
        );
      } else if (this.userType == 'professional') {
        this.profiData.forgottenPassword(emailObj).subscribe(
          data => {
            console.log('respons data', data)
            this.snackBar.openSnackBar('Успешно дадохте заявка, за смяна на парола, ще получите съобщение в Електронната поща, за потвърждение.', 'Затвори');
            this.dialogRef.close();
          }
        );
      } else if (this.userType == 'admin') {
        this.adminData.forgottenPassword(emailObj).subscribe(
          data => {
            console.log('respons data', data)
            this.snackBar.openSnackBar('Успешно дадохте заявка, за смяна на парола, ще получите съобщение в Електронната поща, за потвърждение.', 'Затвори');
            this.dialogRef.close();
          }
        );
      }

    } else {
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  close() {
    this.dialogRef.close();
  }

}

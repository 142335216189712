import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OepRegistarComponent } from './oep-registar/oep-registar.component';
import { OepRegistratsiaComponent } from './oep-registratsia/oep-registratsia.component';
import { OepRakovodstvoComponent } from './oep-rakovodstvo/oep-rakovodstvo.component';
import { PoleznaInformatsiaComponent } from './polezna-informatsia/polezna-informatsia.component';
import { OepReshavaneProblemComponent } from './oep-reshavane-problem/oep-reshavane-problem.component';
import { InformatsiaIVrazkiComponent } from './informatsia-i-vrazki/informatsia-i-vrazki.component';

import { DsIskamDaZnamComponent } from './ds-iskam-da-znam/ds-iskam-da-znam.component';
import { FOtvorenaVrataComponent } from './f-otvorena-vrata/f-otvorena-vrata.component';
import { ZaProektaComponent } from './za-proekta/za-proekta.component';
import { NoviniComponent } from './novini/novini.component';
import { DobriPraktikiComponent } from './dobri-praktiki/dobri-praktiki.component';
import { HoraSUvrezhdaniaSadaComponent } from './hora-s-uvrezhdania-sada/hora-s-uvrezhdania-sada.component';
import { HoraSUvrezhdaniaPravaComponent } from './hora-s-uvrezhdania-prava/hora-s-uvrezhdania-prava.component';
import { SignaliComponent } from './signali/signali.component';
import { ForumPravilaComponent } from './forum-pravila/forum-pravila.component';
import { ForumOsnovenComponent } from './forum-osnoven/forum-osnoven.component';
import { EMediatsiyaComponent } from './e-mediatsiya/e-mediatsiya.component';
import { MoitePravaComponent } from './moite-prava/moite-prava.component';
import { ProfileComponent } from './profile/profile.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { ProfessionalRegistrationComponent } from './professional-registration/professional-registration.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProfessionalProfileComponent } from './professional-profile/professional-profile.component';
import { AlgorithmsComponent } from './algorithms/algorithms.component';
import { CourtComponent } from './informatsia-i-vrazki-all/court/court.component';
import { ProsecutorComponent } from './informatsia-i-vrazki-all/prosecutor/prosecutor.component';
import { ArbitrationComponent } from './informatsia-i-vrazki-all/arbitration/arbitration.component';
import { MediatorsComponent } from './informatsia-i-vrazki-all/mediators/mediators.component';
import { InvestigativeBodiesComponent } from './informatsia-i-vrazki-all/investigative-bodies/investigative-bodies.component';
import { InvestigativeBodiesSledstveniComponent } from './informatsia-i-vrazki-all/investigative-bodies-sledstveni/investigative-bodies-sledstveni.component';
import { BailiffsComponent } from './informatsia-i-vrazki-all/bailiffs/bailiffs.component';
import { EntryJudgesComponent } from './informatsia-i-vrazki-all/entry-judges/entry-judges.component';
import { NotariesComponent } from './informatsia-i-vrazki-all/notaries/notaries.component';
import { LawyersComponent } from './informatsia-i-vrazki-all/lawyers/lawyers.component';
import { ImamSporComponent } from './algorithms-all/imam-spor/imam-spor.component';
import { TarsiaZashtitaComponent } from './algorithms-all/tarsia-zashtita/tarsia-zashtita.component';
import { TarsiaSposobComponent } from './algorithms-all/tarsia-sposob/tarsia-sposob.component';
import { ObrazuvanoSledstvenoComponent } from './algorithms-all/obrazuvano-sledstveno/obrazuvano-sledstveno.component';
import { ObrazuvanoSadebnoComponent } from './algorithms-all/obrazuvano-sadebno/obrazuvano-sadebno.component';
import { ObrazuvanoIzpalnitelnoComponent } from './algorithms-all/obrazuvano-izpalnitelno/obrazuvano-izpalnitelno.component';
import { VatreshnaSredaComponent } from './vatreshna-sreda/vatreshna-sreda.component';
import { KakviSaPravataMiComponent } from './kakvi-sa-pravata-mi/kakvi-sa-pravata-mi.component';
import { AlgorithmByIdComponent } from './algorithm-by-id/algorithm-by-id.component';
import { NoviniByIdComponent } from './novini-by-id/novini-by-id.component';
import { ForumSpetsializiranComponent } from './forum-spetsializiran/forum-spetsializiran.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminNoviniComponent } from './admin/admin-novini/admin-novini.component';
import { AdminAlgorithmsComponent } from './admin/admin-algorithms/admin-algorithms.component';
import { AdminForumComponent } from './admin/admin-forum/admin-forum.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminProfessionalsComponent } from './admin/admin-professionals/admin-professionals.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  {
    path: 'registar-oep',
    component: OepRegistarComponent
  },
  {
    path: 'registratsia-oep',
    component: OepRegistratsiaComponent
  },
  {
    path: 'rakovodstvo-oep',
    component: OepRakovodstvoComponent
  },
  {
    path: 'polezna-informatsia',
    component: PoleznaInformatsiaComponent
  },
  {
    path: 'reshavane-na-praven-problem',
    component: OepReshavaneProblemComponent
  },
  {
    path: 'informatsia-i-vrazki',
    children: [
      {
        path: '',
        component: InformatsiaIVrazkiComponent
      },
      {
        path: 'court/:tab',
        component: CourtComponent
      },
      {
        path: 'prosecutor/:tab',
        component: ProsecutorComponent
      },
      {
        path: 'arbitration/:tab',
        component: ArbitrationComponent
      },
      {
        path: 'mediators/:tab',
        component: MediatorsComponent
      },
      {
        path: 'investigative-bodies/:tab',
        component: InvestigativeBodiesComponent
      },
      {
        path: 'investigative-bodies-sledstveni/:tab',
        component: InvestigativeBodiesSledstveniComponent
      },
      {
        path: 'bailiffs/:tab',
        component: BailiffsComponent
      },
      {
        path: 'entry-judges/:tab',
        component: EntryJudgesComponent
      },
      {
        path: 'notaries/:tab',
        component: NotariesComponent
      },
      {
        path: 'lawyers/:tab',
        component: LawyersComponent
      }
    ]
  },
  {
    path: 'ds-iskam-da-znam',
    component: DsIskamDaZnamComponent
  },
  {
    path: 'f-otvorena-vrata',
    component: FOtvorenaVrataComponent
  },
  {
    path: 'za-proekta',
    component: ZaProektaComponent
  },
  {
    path: 'novini',
    children: [
      {
        path: '',
        component: NoviniComponent
      },
      {
        path: ':id',
        component: NoviniByIdComponent
      }
    ]
  },
  {
    path: 'dobri-praktiki',
    component: DobriPraktikiComponent
  },
  {
    path: 'osnoven-forum',
    component: ForumOsnovenComponent
  },
  {
    path: 'forum-spetsializiran',
    component: ForumSpetsializiranComponent
  },
  {
    path: 'forum-pravila',
    component: ForumPravilaComponent
  },
  {
    path: 'hora-s-uvrezhdania-sada',
    component: HoraSUvrezhdaniaSadaComponent
  },
  {
    path: 'hora-s-uvrezhdania-prava',
    component: HoraSUvrezhdaniaPravaComponent
  },
  {
    path: 'signali',
    component: SignaliComponent
  },
  {
    path: 'e-mediatsiya',
    component: EMediatsiyaComponent
  },
  {
    path: 'moite-prava',
    component: MoitePravaComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'user-login',
    component: UserLoginComponent
  },
  {
    path: 'user-registration',
    component: UserRegistrationComponent
  },
  {
    path: 'professional-registration',
    component: ProfessionalRegistrationComponent
  },
  {
    path: 'user-profile/:id',
    component: UserProfileComponent
  },
  {
    path: 'professional-profile/:id',
    component: ProfessionalProfileComponent
  },
  {
    path: 'algorithms',
    children: [
      {
        path: '',
        component: AlgorithmsComponent
      },
      {
        path: 'imam-spor',
        component: ImamSporComponent
      },
      {
        path: 'tarsia-zashtita',
        component: TarsiaZashtitaComponent
      },
      {
        path: 'tarsia-sposob',
        component: TarsiaSposobComponent
      },
      {
        path: 'obrazuvano-sledstveno',
        component: ObrazuvanoSledstvenoComponent
      },
      {
        path: 'obrazuvano-sadebno',
        component: ObrazuvanoSadebnoComponent
      },
      {
        path: 'obrazuvano-izpalnitelno',
        component: ObrazuvanoIzpalnitelnoComponent
      },
      {
        path: ':id',
        component: AlgorithmByIdComponent
      }
    ]
  },
  {
    path: 'vatreshna-sreda',
    component: VatreshnaSredaComponent
  },
  {
    path: 'kakvi-sa-pravata-mi',
    component: KakviSaPravataMiComponent
  },
  {
    path: 'mp-admin',
    component: AdminLoginComponent
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        component: AdminComponent
      },
      {
        path: 'home',
        component: AdminHomeComponent
      },
      {
        path: 'novini',
        component: AdminNoviniComponent
      },
      {
        path: 'algorithms',
        component: AdminAlgorithmsComponent
      },
      {
        path: 'forum',
        component: AdminForumComponent
      },
      {
        path: 'professionals',
        component: AdminProfessionalsComponent
      },
      {
        path: 'users',
        component: AdminUsersComponent
      }
    ]
  },
  {
    path: 'change-password/:userType/:tkn',
    component: ChangePasswordComponent
  },
  {
    path: '',
    component: HomeComponent
  }
  // { 
  //   path: '**', 
  //   component: PageNotFoundComponent // PAGE 404
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class OepRegistarService {

  constructor(private http: HttpClient) { }

  getAllProfessioanlsByType(offset: number, limit: number, proType: number) {
    return this.http.get<any>(environment.professionals_by_type + proType + '/pagination' + '?offset=' + offset + '&limit=' + limit);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oep-reshavane-problem',
  templateUrl: './oep-reshavane-problem.component.html',
  styleUrls: ['./oep-reshavane-problem.component.css']
})
export class OepReshavaneProblemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBarService } from '../services/snackbar.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-dialog-confirm-password',
  templateUrl: './dialog-confirm-password.component.html',
  styleUrls: ['./dialog-confirm-password.component.css']
})
export class DialogConfirmPasswordComponent implements OnInit {

  @ViewChild('resetconfirmPasswordFormGroup', {
    static: true
  }) resetconfirmPasswordFormGroup: NgForm;

  confirmPasswordFormGroup: FormGroup;
  description: string;
  userType: any;

  showLoader: boolean;
  newMainAdminName: string;
  newMainAdminId: any;

  visibility: string = 'visibility_off';
  inputType: string = 'password';

  constructor(private dialogRef: MatDialogRef<DialogConfirmPasswordComponent>, @Inject(MAT_DIALOG_DATA) dialogData, private data: AdminService, private formBuilder: FormBuilder, private snackBar: SnackBarService) {
    this.newMainAdminId = dialogData.newMainAdminId;
    this.newMainAdminName = dialogData.newMainAdminName;
  }

  ngOnInit() {
    this.confirmPasswordFormGroup = this.formBuilder.group({
      password: ['', Validators.required]
    });
  }

  onSelectMainAdmin() {

    this.showLoader = true;

    if (this.confirmPasswordFormGroup.valid) {

      let passObj = {
        id: this.newMainAdminId,
        password: this.confirmPasswordFormGroup.controls.password.value
      }

      this.data.confirmPassword(passObj).subscribe(
        data => {
          console.log('respons data', data)
          this.snackBar.openSnackBar('Успешно дадохте правата, за главен админ на ' + this.newMainAdminName + '.', 'Затвори');
          this.dialogRef.close();
        }
      );

    } else {
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  passwordVisibility() {
    if (this.visibility == 'visibility') {
      this.visibility = 'visibility_off';
      this.inputType = 'password';
    } else {
      this.visibility = 'visibility';
      this.inputType = 'text';
    }
  }

  close() {
    this.dialogRef.close();
  }

}

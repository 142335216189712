import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { SnackBarService } from '../../services/snackbar.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { MatSidenav } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment, fileType } from '../../services/constants.service';

@Component({
  selector: 'app-admin-novini',
  templateUrl: './admin-novini.component.html',
  styleUrls: ['./admin-novini.component.css']
})
export class AdminNoviniComponent implements OnInit {

  @ViewChild('resetAddNewsForm', {
    static: true
  }) resetAddNewsForm: NgForm;

  @ViewChild('addSidenavNews', {
    static: false
  }) addSidenavNews: MatSidenav;

  addNewsFormGroup: FormGroup;

  showLoader: boolean = true;

  rowsNews = <any>[];
  columnsNews = <any>[];

  @Input() prop!: number;
  loadingIndicator: boolean;
  reorderable = true;
  swapColumns = true;
  SelectionType = SelectionType;

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;

  @ViewChild('actionTemplate', { static: false }) actionTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
  @ViewChild('headerActionTemplate', { static: false }) headerActionTemplate: TemplateRef<any>;
  @ViewChild('imageTemplate', { static: false }) imageTemplate: TemplateRef<any>;

  TableSettings = {
    messages: {
      emptyMessage: `
        <div class="padding-one" style>
        <span>Нямате заявки, за показване.</span>
        </div>
        `
    }
  }
  messages = this.TableSettings.messages;

  title: string;

  newsPermission: number;
  imageName: any;
  imageUrl: any;
  fileType: number = fileType.type[0].value;
  imgBaseUrl: string = environment.upload_news_image

  selectedFile: File = null;
  color = 'primary';
  mode = 'determinate';
  value = 0;
  bufferValue = 0;

  constructor(private data: AdminService, private snackBar: SnackBarService, private formBuilder: FormBuilder, private cookieService: CookieService, private http: HttpClient) { }

  ngAfterViewInit(): void {
    this.makeTable();
  }

  ngOnInit() {
    this.makeTable();
    this.createForms();
    this.onGetNewsPaginated(this.offset, this.limit);
    this.newsPermission = parseInt(this.cookieService.get('newsPermission'));
  }

  makeTable() {

    this.columnsNews = [
      { name: 'Заглавие', prop: "title", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Пояснение', prop: "description", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Текст', prop: "text", width: 300, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Дата на създаване', prop: "createdDate", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Изображение', prop: "source", width: 200, value: 1, cellTemplate: this.imageTemplate, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

  }

  createForms() {

    this.addNewsFormGroup = this.formBuilder.group({
      id: [0],
      title: [''],
      description: [''],
      text: ['']
    });

  }

  onGetNewsPaginated(offset: number, limit: number) {
    this.showLoader = true;

    this.data.getNewsPaginated(offset, limit).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }
        this.rowsNews = data.resultList;
        this.showLoader = false;
      }
    );
  }

  onAddNews() {

    this.showLoader = true;

    if (this.addNewsFormGroup.controls.title.value && this.addNewsFormGroup.controls.description.value && this.addNewsFormGroup.controls.text.value) {

      let newsObj = {
        id: this.addNewsFormGroup.controls.id.value,
        title: this.addNewsFormGroup.controls.title.value,
        description: this.addNewsFormGroup.controls.description.value,
        text: this.addNewsFormGroup.controls.text.value,
        source: this.imageName
      }

      if (this.title == 'Добавяне на новина') {

        this.data.addNews(newsObj).subscribe(
          data => {
            this.resetPagination();
            this.onGetNewsPaginated(this.offset, this.limit);
            this.addSidenavNews.close();
            this.addNewsFormGroup.reset();
            this.showLoader = false;
            this.snackBar.openSnackBar('Успешно добавихте новина.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          }
        )

      } else {

        this.data.editNews(newsObj).subscribe(
          data => {
            this.resetPagination();
            this.onGetNewsPaginated(this.offset, this.limit);
            this.addSidenavNews.close();
            this.addNewsFormGroup.reset();
            this.showLoader = false;
            this.snackBar.openSnackBar('Успешно редактирахте новината.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          }
        )

      }


    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  onDeleteNews(row) {
    
    this.showLoader = true;

    this.data.deleteNews(row.id).subscribe(
      data => {
        this.resetPagination();
        this.onGetNewsPaginated(this.offset, this.limit);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте новината.', 'Затвори');
      }
    );
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    this.onUploadImage();
  }

  onFileDelete() {
    this.imageUrl = '';
    this.imageName = '';
    this.selectedFile = null;
    this.value = 0;
  }

  onUploadImage() {

    const fb = new FormData;
    fb.append('file', this.selectedFile, this.selectedFile.name);

    this.data.uploadImage(this.fileType, fb).subscribe(
      data => {
        console.log('data', data)
        if (data.type === HttpEventType.UploadProgress) {
          this.value = Math.round(data.loaded / data.total * 100);
        } else if (data.type == HttpEventType.Response) {
          this.imageName = data.body['fileName'];
          this.imageUrl = environment.upload_news_image + this.fileType + '/' + data.body['fileName'];
        }
      }
    );

  }

  openAddNews() {

    this.title = 'Добавяне на новина';
    this.addSidenavNews.open();
  }

  openEditNews(row) {
    console.log('row', row)
    this.title = 'Редакция на новина';

    this.addNewsFormGroup.patchValue({
      id: row.id,
      title: row.title,
      description: row.description,
      text: row.text
    });

    this.imageName = row.source;
    this.imageUrl = environment.upload_news_image + this.fileType + '/' + row.source;
    this.value = 100;

    this.addSidenavNews.open();
  }

  newsPageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetNewsPaginated(this.offset, this.limit);
    } else if (event == 1) {
      this.resetPagination();
      this.onGetNewsPaginated(this.offset, this.limit);
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

  close(reason: string) {
    this.addSidenavNews.close();
  }

}

import { Component, OnInit } from '@angular/core';
import { NoviniService } from '../services/novini.service';
import { environment } from '../services/constants.service';
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-novini',
  templateUrl: './novini.component.html',
  styleUrls: ['./novini.component.css']
})
export class NoviniComponent implements OnInit {

    // Accessibility variables
    fonts: Fonts[] = accessibility.fonts
    fontSizes: string[] = accessibility.fontSizes;
  
    toggle: string = 'close';
    currentSizePosition: number = 1;
  
    public font: Font = new Font({
      family: ' ',
      size: ' ',
      style: 'regular',
      styles: ['regular'],
    });
    // End

  news = [
    {
      name: 'Стартира европейски проект за защита на човешки и граждански права',
      image: 'http://moetopravo.bg/wp-content/uploads/2021/02/Press.png'
    },
    {
      name: 'Европейски проект повишава правната грамотност на гражданите ',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/02/unnamed.jpg'
    },
    {
      name: 'ОЕП бе представена в програма „ДНЕС“  на Радио Шумен',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/09/RSH-870x530.png'
    },
    {
      name: 'Обявиха резултатите от изпълнението на съдебен проект',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/08/1.png'
    },
    {
      name: 'Фондация "Отворена Врата" проведе работна среща с магистрати в Перник',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/07/IMG_6607-870x530.jpg'
    },
    {
      name: 'Работна среща в гр.Трън',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/07/IMG_6557-870x530.jpg'
    },
    {
      name: 'Нови назначения в окръжен съд - Перник',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/07/rajonen-sad-870x530.jpg'
    },
    {
      name: 'Работна среща с магистрати',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/06/IMG_6416-870x530.jpg'
    },
    {
      name: 'Работна среща с представители на съюза на инвалидите в българия - гр. Перник',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/06/IMG_6399-870x530.jpg'
    },
    {
      name: 'Съдебен заседател под домашен арест по искане на Италия',
      image: 'http://moetopravo.bg/wp-content/uploads/2020/06/350a99e98a703678-1024x682-870x530.jpg'
    }
  ];

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;
  baseUrl: string;
  showLoader: boolean = true;

  constructor(private data: NoviniService) { }

  ngOnInit() {
    this.onGetAllNews();
    this.baseUrl = environment.base_url;
  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

  onGetAllNews() {

    this.data.getAllNews(this.offset, this.limit).subscribe(
      data => {
        console.log('respons data', data,data.totalResult)

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }

        this.news = data.resultList;
        this.showLoader = false;
      }
    );

  }

  PageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetAllNews();
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAllNews();
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

}

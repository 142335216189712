import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DialogForgottenPasswordComponent } from 'src/app/dialog-forgotten-password/dialog-forgotten-password.component';
import { AdminService } from 'src/app/services/admin.service';
import { SnackBarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  @ViewChild('resetAdminLoginForm', {
    static: true
  }) resetAdminLoginForm: NgForm;

  adminLoginForm: FormGroup;
  visibility: string = 'visibility_off';
  inputType: string = 'password';

  adminId: string;
  adminName: string;
  adminEmail: string;

  showLoader: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: AdminService, private cookieService: CookieService, private snackBar: SnackBarService, private dialog: MatDialog) { }

  ngOnInit() {
    this.createForms();
  }

  createForms() {
    this.adminLoginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onAdminLogin() {

    this.showLoader = true;

    if (this.adminLoginForm.valid) {

      let adminObj = {
        email: this.adminLoginForm.controls.login.value,
        password: this.adminLoginForm.controls.password.value,
      }

      this.data.adminLogin(adminObj).subscribe(
        data => {
          this.cookieService.set('adminId', data['id']);
          this.cookieService.set('adminName', data['name']);
          this.cookieService.set('adminEmail', data['email']);
          this.cookieService.set('token', data['token']);
          this.cookieService.set('adminPermission', data['adminPermission']);
          this.cookieService.set('algorithmPermission', data['algorithmPermission']);
          this.cookieService.set('forumPermission', data['forumPermission']);
          this.cookieService.set('newsPermission', data['newsPermission']);
          this.cookieService.set('pagePermission', data['pagePermission']);
          this.cookieService.set('userType', data['userType']);
          this.cookieService.set('isMaster', data['isMaster']);
          console.log('All cookies', this.cookieService.getAll())
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно влязохте в админ портал!', 'Затвори');
          this.resetValidation();
          this.router.navigate(['admin']);
        },
        error => {
          if (error.status == 401) {
            this.snackBar.openSnackWrongBar('Акаунтът, с който се опитвате да влезете, е неправилен.', 'Затвори');
            this.showLoader = false;
          }
        }
      );

    } else {
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
      this.showLoader = false;
    }

  }

  onForgottenPassword(userType) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;


    dialogConfig.data = {
      id: 1,
      userType: userType
    };

    this.dialog.open(DialogForgottenPasswordComponent, dialogConfig);

  }

  resetValidation() {
    this.resetAdminLoginForm.resetForm();
  }

  passwordVisibility() {
    if (this.visibility == 'visibility') {
      this.visibility = 'visibility_off';
      this.inputType = 'password';
    } else {
      this.visibility = 'visibility';
      this.inputType = 'text';
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { DialogKakviSaPravataMiComponent } from '../dialog-kakvi-sa-pravata-mi/dialog-kakvi-sa-pravata-mi.component';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-kakvi-sa-pravata-mi',
  templateUrl: './kakvi-sa-pravata-mi.component.html',
  styleUrls: ['./kakvi-sa-pravata-mi.component.css']
})
export class KakviSaPravataMiComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  allInfo = [
    {
      id: 0,
      title: 'Медиация',
      message: 'Медиацията е способ за доброволно разрешаване на спорове, при който страните се подпомагат от трето, неутрално и безпристрастно лице – медиатор, за постигане на взаимно приемливо споразумение то, обаче не решава спора. Медиацията е един от способите за извънсъдебно разрешаване на спорове, при който страните сами определят съдържанието на споразумението и ако не постигнат съгласие то не може да им бъде наложено от трето лице – „решаващ орган”. Урежда се в Закон за медиацията и е приложима при граждански, търговски, трудови, семейни и административни спорове, свързани с права на потребители, и други спорове между физически и/или юридически лица. При определена категория наказателни дела също е възможно да се приложи.'
    },
    {
      id: 1,
      title: 'Арбитраж',
      message: 'Арбитражът е способ за извънсъдебно решаване на спорове, при който страните решават да отнесат своя спор до трето лице или група лица – арбитри. Доброволността при арбитража приключва до съгласието да се отнесе спорът и евентуално определянето на арбитрите. След това страните са длъжни да приемат решението на арбитражния състав такова, каквото той го произнесе – също като при съда. Предмет на арбитраж могат да бъдат имуществени гражданскоправни отношения. За разлика от съдебните спорове тези пред арбитраж се развиват на една инстанция. Прилага се Законът за международни търговски арбитраж (ЗМТА)'
    },
    {
      id: 2,
      title: 'Споразумения',
      message: 'Спогодбата е способ, при който страните могат и извънсъдебно да решат възникнал между тях правен спор и без намесата на трето лице. Този спор трябва да е граждански и имуществен. За целта те трябва обаче да познават правната природа на спора, който имат. Една от причините е, че законът изисква да се спази формата за отделните видове сделки.  Възможно е с една спогодба да се решат няколко въпроса по различни отношения между страните. Този способ е уреден в чл.365 и сл. от Закона за задълженията и договорите (ЗЗД).'
    },
    {
      id: 3,
      title: 'Доброволна делба',
      message: 'При доброволната делба не е задължително да има спор. Тя се прилага като способ за прекратяване на съсобствеността върху вещи и превръщането им в отделни права на собственост върху самостоятелни обекти. Трябва да участват всички съсобственици. При нея в писмена форма с нотариална заверка на подписите се отразява волята на страните, а не на решаващ орган. За да бъде тя действителна обектът на правото на собственост трябва да е поделяем и да се спази формата за извършване на сделката ако има такава (нотариална за недвижимост).'
    },
    {
      id: 4,
      title: 'Делба - Дарение',
      message: 'Това са видове сделки, които едно лице извършва с имуществото си, движимо и недвижимо, приживе с оглед разпределението между близки лица (не е задължително да са от кръга на очакваните наследници). Трябва обаче да се държи сметка, че наследниците общо и по между си имат запазени по закон части. Ако лицето се е разпоредило с тях то неговото разпореждане е атакуемо по съдебен ред. В зависимост от това какво е имуществото дарението трябва да бъде в изискуемата от закона форма (нотариално за недвижим имот). Основна разлика е , че при дарението вещно-прехвърлителният ефект настъпва веднага, а при завещанието след смъртта на завещателя.'
    },
    {
      id: 5,
      title: 'Делба - Завещание',
      message: 'Това са видове сделки, които едно лице извършва с имуществото си, движимо и недвижимо, приживе с оглед разпределението между близки лица (не е задължително да са от кръга на очакваните наследници). Трябва обаче да се държи сметка, че наследниците общо и по между си имат запазени по закон части. Ако лицето се е разпоредило с тях то неговото разпореждане е атакуемо по съдебен ред. В зависимост от това какво е имуществото дарението трябва да бъде в изискуемата от закона форма (нотариално за недвижим имот). Основна разлика е , че при дарението вещно-прехвърлителният ефект настъпва веднага, а при завещанието след смъртта на завещателя.'
    },
    {
      id: 6,
      title: 'Вещно право',
      message: 'Урежда отношения по повод придобиване, упражняване, изгубване и защита на вещни права (право на собственост; ограничени вещни права – на строеж, на ползване и на сервитут, както и владението). В споровете за вещни права се прилага Закона за собствеността (ЗС). '
    },
    {
      id: 7,
      title: 'Облигационно право',
      message: 'Урежда отношения по повод изпълнението/неизпълнението на облигационни задължения, обезпечения на кредитори и др. Възникват от различни видове договори, непозволено увреждане, неоснователно обогатяване и др. В споровете, свързани с облигационни отношения се прилага Закон за задълженията и договорите (ЗЗД).'
    },
    {
      id: 8,
      title: 'Семейно право',
      message: 'Урежда имуществени и неимуществени отношения по повод произхода, брака и осиновяването. В споровете, свързани с тези отношения се прилага Семейния кодекс (СК).'
    },
    {
      id: 9,
      title: 'Наследствено право',
      message: 'Урежда отношенията по повод преминаването на имуществото на починало лице (наследодател) върху неговите наследници по закон или завещание. В споровете за наследствени права се прилага Закона за наследството (ЗН).'
    },
    {
      id: 10,
      title: 'Право на интелектуалната собственост',
      message: 'Регламентира отношения по повод създаването, използването и защитата на произведения на литературата, науката и изкуството. В споровете за права на интелекуталната собственост се прилага Закона за авторското право и сродните му права (ЗАПСП).'
    }
  ]

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  showInformation(id: number) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;


    dialogConfig.data = {
      id: this.allInfo[id].id,
      title: this.allInfo[id].title,
      message: this.allInfo[id].message
    };

    this.dialog.open(DialogKakviSaPravataMiComponent, dialogConfig);

  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

}

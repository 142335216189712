import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-obrazuvano-sledstveno',
  templateUrl: './obrazuvano-sledstveno.component.html',
  styleUrls: ['./obrazuvano-sledstveno.component.css']
})
export class ObrazuvanoSledstvenoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ForumService } from '../../services/forum.service';
import { SnackBarService } from '../../services/snackbar.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-admin-forum',
  templateUrl: './admin-forum.component.html',
  styleUrls: ['./admin-forum.component.css']
})
export class AdminForumComponent implements OnInit {

  forumPermission: number;

  // Osnoven

  @ViewChild('resetAddTopicForm', {
    static: true
  }) resetAddTopicForm: NgForm;

  @ViewChild('resetEditTopicForm', {
    static: true
  }) resetEditTopicForm: NgForm;

  @ViewChild('resetAddCommentForm', {
    static: true
  }) resetAddCommentForm: NgForm;

  @ViewChild('editSidenavTopic', {
    static: false
  }) editSidenavTopic: MatSidenav;

  @ViewChild('editSidenavComment', {
    static: false
  }) editSidenavComment: MatSidenav;

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number = 0;

  showLoader: boolean = true;
  topics: any = '';
  comments: any = '';

  title: string;
  description: string;

  addTopicFormGroup: FormGroup;
  editTopicFormGroup: FormGroup;
  addCommentFormGroup: FormGroup;
  editCommentFormGroup: FormGroup;
  isLogged: boolean = false;

  topicsShow: boolean = true;
  commentsShow: boolean = false;
  activeCommentUid: string;

  id: string;
  forumType: string = 'osnoven';

  // // // // //

  // Spetzializiran

  profiTypes: any[] = [
    { value: '0', viewValue: 'Адвокати' },
    { value: '1', viewValue: 'Арбитри' },
    { value: '2', viewValue: 'Мадиатори' },
    { value: '3', viewValue: 'Нотариуси' },
    { value: '4', viewValue: 'Съдебни Изпълнители' },
    { value: '5', viewValue: 'Юрист или Специалисти в администрация' },
    { value: '6', viewValue: 'Омбудсмани' }
  ];

  @ViewChild('resetAddTopicProForm', {
    static: true
  }) resetAddTopicProForm: NgForm;

  @ViewChild('resetAddCommentProForm', {
    static: true
  }) resetAddCommentProForm: NgForm;

  @ViewChild('editSidenavTopicPro', {
    static: false
  }) editSidenavTopicPro: MatSidenav;

  @ViewChild('editSidenavCommentPro', {
    static: false
  }) editSidenavCommentPro: MatSidenav;

  limitPro: number = 10;
  offsetPro: number = 0;
  cPagePro: number = 1;
  totalResultPro: number = 0;

  // showLoader: boolean = true;
  topicsPro: any = '';
  commentsPro: any = '';

  titlePro: string;
  descriptionPro: string;

  addTopicProFormGroup: FormGroup;
  editTopicProFormGroup: FormGroup;
  addCommentProFormGroup: FormGroup;
  editCommentProFormGroup: FormGroup;
  // isLogged: boolean = false;

  topicsShowPro: boolean = true;
  commentsShowPro: boolean = false;
  activeCommentUidPro: string;

  userType: string;
  idPro: string

  // // // // //

  constructor(private data: ForumService, private snackBar: SnackBarService, private formBuilder: FormBuilder, private cookieService: CookieService) { }

  ngOnInit() {
    console.log('this.cookieService.getAll()', this.cookieService.getAll())
    if (this.cookieService.get('adminId')) {
      this.isLogged = true;
      this.id = this.cookieService.get('adminId');
      this.userType = this.cookieService.get('userType');
      this.forumPermission = parseInt(this.cookieService.get('forumPermission'));
    }

    // Osnoven
    this.onGetAllTopics();
    this.createForms();
    // // // // //

    // Spetzialiria
    this.onGetAllProTopics();
    this.createProForms();
    // // // // //
  }

  // Osnoven

  createForms() {
    this.addTopicFormGroup = this.formBuilder.group({
      title: [''],
      description: [''],
    });

    this.editTopicFormGroup = this.formBuilder.group({
      title: [''],
      description: [''],
      uid: ['']
    });

    this.addCommentFormGroup = this.formBuilder.group({
      comment: ['']
    })

    this.editCommentFormGroup = this.formBuilder.group({
      comment: [''],
      uid: ['']
    });
  }

  showByForumType(type: string) {
    this.forumType = type;
  }

  showTopicsOrComments(choice: string) {
    if (choice == 'topics') {
      this.topicsShow = true;
      this.commentsShow = false;
      this.resetPagination();
      this.onGetAllTopics();
    } else if (choice == 'comments') {
      this.topicsShow = false;
      this.commentsShow = true;
      this.resetPagination();
    } else {
      this.topicsShow = false;
      this.commentsShow = false;
    }
  }

  // Topics

  onGetAllTopics() {

    let categoryId = 0;

    this.data.getAllTopics(categoryId, this.offset, this.limit).subscribe(
      data => {

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }

        this.topics = data.resultList;
        this.showLoader = false;
        console.log('response', data);
      })

  }

  onAddTopic() {

    this.showLoader = true;

    if (this.addTopicFormGroup.controls.title.value && this.addTopicFormGroup.controls.description.value) {

      let addTopicObj = {
        title: this.addTopicFormGroup.controls.title.value,
        description: this.addTopicFormGroup.controls.description.value,
        categoryId: 0
      }

      this.data.addTopic(addTopicObj).subscribe(
        data => {
          this.onGetAllTopics();
          this.addTopicFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onEditTopic() {

    this.showLoader = true;

    if (this.editTopicFormGroup.controls.title.value && this.editTopicFormGroup.controls.description.value) {

      let editTopicObj = {
        uid: this.editTopicFormGroup.controls.uid.value,
        title: this.editTopicFormGroup.controls.title.value,
        description: this.editTopicFormGroup.controls.description.value,
        // categoryId: 0
      }

      this.data.editTopic(editTopicObj).subscribe(
        data => {
          this.onGetAllTopics();
          this.editSidenavTopic.close();
          this.editTopicFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно редактирахте тема.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onDeleteTopic(uid: string) {

    this.showLoader = true;

    this.data.deleteTopic(uid).subscribe(
      data => {
        this.onGetAllTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте вашата тема.', 'Затвори');
      }
    )

  }

  openeditSidenavTopic(topic: any) {
    console.log('editSidenavTopic.open();', topic)

    this.editTopicFormGroup.patchValue({
      title: topic.title,
      description: topic.description,
      uid: topic.uid
    });

    this.editSidenavTopic.open();
  }

  onUpVoteTopic(uid: string) {

    let upVoteObj = {
      uid: uid
    }

    this.data.upVoteTopic(upVoteObj).subscribe(
      data => {
        this.onGetAllTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  onDownVoteTopic(uid: string) {

    let downVoteObj = {
      uid: uid
    }

    this.data.downVoteTopic(downVoteObj).subscribe(
      data => {
        this.onGetAllTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  PageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetAllTopics();
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAllTopics();
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

  // Comments

  goToCommentById(uid: string) {
    console.log('awdawd', uid)
    this.activeCommentUid = uid;
    this.showTopicsOrComments('comments');
    this.onGetAllCommentsById(uid);
  }

  onGetAllCommentsById(uid: string) {
    this.data.getAllComments(this.offset, this.limit, uid).subscribe(
      data => {

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }

        this.comments = data.resultList;
        this.showLoader = false;
        console.log('response', data);
      })
  }

  onAddComment() {

    this.showLoader = true;

    if (this.addCommentFormGroup.controls.comment.value) {

      let addCommentObj = {
        comment: this.addCommentFormGroup.controls.comment.value,
        postUid: this.activeCommentUid
      }

      this.data.addComment(addCommentObj).subscribe(
        data => {
          this.onGetAllCommentsById(this.activeCommentUid);
          this.addCommentFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте коментар.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onEditComment() {

    this.showLoader = true;

    if (this.editCommentFormGroup.controls.comment.value) {

      let editCommentObj = {
        uid: this.editCommentFormGroup.controls.uid.value,
        comment: this.editCommentFormGroup.controls.comment.value,
        // categoryId: 0
      }

      this.data.editComment(editCommentObj).subscribe(
        data => {
          this.onGetAllCommentsById(this.activeCommentUid);
          this.editSidenavComment.close();
          this.editCommentFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно редактирахте коментара.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  onDeleteComment(uid: string) {

    this.showLoader = true;

    this.data.deleteComment(uid).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUid);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте вашият коментар.', 'Затвори');
      }
    )

  }

  openeditSidenavComment(comment: any) {

    this.editCommentFormGroup.patchValue({
      comment: comment.comment,
      uid: comment.uid
    });

    this.editSidenavComment.open();
  }

  onUpVoteComment(uid: string) {

    let upVoteObj = {
      uid: uid
    }

    this.data.upVoteComment(upVoteObj).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUid);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно дадохте положителен рейтинг.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  onDownVoteComment(uid: string) {

    let downVoteObj = {
      uid: uid
    }

    this.data.downVoteComment(downVoteObj).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUid);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно дадохте отрицателен рейтинг.', 'Затвори');
      },
      error => {
        if (error.status != 200) {
          this.showLoader = false;
          this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
        }
      }
    )

  }

  close(reason: string) {
    this.editSidenavComment.close();
    this.editSidenavTopic.close();
  }

  // // // // //

  // Spetzializiran

  createProForms() {
    this.addTopicProFormGroup = this.formBuilder.group({
      profiType: [''],
      title: [''],
      description: [''],
    });

    this.editTopicProFormGroup = this.formBuilder.group({
      title: [''],
      description: [''],
      uid: ['']
    });

    this.addCommentProFormGroup = this.formBuilder.group({
      comment: ['']
    })

    this.editCommentProFormGroup = this.formBuilder.group({
      comment: [''],
      uid: ['']
    });
  }

  showTopicsOrCommentsPro(choice: string) {
    if (choice == 'topics') {
      this.topicsShowPro = true;
      this.commentsShowPro = false;
      this.resetPaginationPro();
      this.onGetAllProTopics();
    } else if (choice == 'comments') {
      this.topicsShowPro = false;
      this.commentsShowPro = true;
      this.resetPaginationPro();
    } else {
      this.topicsShowPro = false;
      this.commentsShowPro = false;
    }
  }

  // Topics

  onGetAllProTopics() {

    let categoryId = 0;

    this.data.getAllPrivateTopics(categoryId, this.offsetPro, this.limitPro).subscribe(
      data => {

        this.totalResultPro = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultPro === data.totalResult) {
          this.totalResultPro = this.totalResultPro - Math.floor(data.totalResult / 10);
        }

        this.topicsPro = data.resultList;
        this.showLoader = false;
        console.log('response', data);
      })

  }

  onAddTopicPro() {

    this.showLoader = true;

    if (this.addTopicProFormGroup.controls.title.value && this.addTopicProFormGroup.controls.description.value) {

      let addTopicObj = {
        title: this.addTopicProFormGroup.controls.title.value,
        description: this.addTopicProFormGroup.controls.description.value,
        proViewType: parseInt(this.addTopicProFormGroup.controls.profiType.value)
      }

      this.data.addPrivateTopic(addTopicObj).subscribe(
        data => {
          this.onGetAllProTopics();
          this.addTopicProFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте тема.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onEditPrivateTopicPro() {

    this.showLoader = true;

    if (this.editTopicProFormGroup.controls.title.value && this.editTopicProFormGroup.controls.description.value) {

      let editTopicObj = {
        uid: this.editTopicProFormGroup.controls.uid.value,
        title: this.editTopicProFormGroup.controls.title.value,
        description: this.editTopicProFormGroup.controls.description.value,
        // categoryId: 0
      }

      this.data.editPrivateTopic(editTopicObj).subscribe(
        data => {
          this.onGetAllProTopics();
          this.editTopicProFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно редактирахте тема.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onDeletePrivateTopicPro(uid: string) {

    this.showLoader = true;

    this.data.deletePrivateTopic(uid).subscribe(
      data => {
        this.onGetAllProTopics();
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте вашата тема.', 'Затвори');
      }
    )

  }

  openeditSidenavTopicPro(topic: any) {
    console.log('editSidenavTopic.open();', topic)

    this.editTopicProFormGroup.patchValue({
      title: topic.title,
      description: topic.description,
      uid: topic.uid
    });

    this.editSidenavTopicPro.open();
  }

  PageChangedPro(event) {
    this.cPagePro = event;
    this.offsetPro = 10 * (event - 1);
    if (this.limitPro == this.offsetPro || this.limitPro < this.offsetPro) {
      this.onGetAllProTopics();
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAllTopics();
    }
  }

  resetPaginationPro() {
    this.limitPro = 10;
    this.offsetPro = 0;
    this.cPagePro = 1;
  }

  // Comments

  goToCommentByIdPro(uid: string) {
    this.activeCommentUid = uid;
    this.showTopicsOrCommentsPro('comments');
    this.onGetAllCommentsByIdPro(uid)
  }

  onGetAllCommentsByIdPro(uid: string) {
    this.data.getAllPrivateComments(this.offsetPro, this.limitPro, uid).subscribe(
      data => {

        this.totalResultPro = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResultPro === data.totalResult) {
          this.totalResultPro = this.totalResultPro - Math.floor(data.totalResult / 10);
        }

        this.commentsPro = data.resultList;
        this.showLoader = false;
        console.log('response', data);
      })
  }

  onAddCommentPro() {

    this.showLoader = true;

    if (this.addCommentProFormGroup.controls.comment.value) {

      let addCommentObj = {
        comment: this.addCommentProFormGroup.controls.comment.value,
        postUid: this.activeCommentUidPro
      }

      this.data.addPrivateComment(addCommentObj).subscribe(
        data => {
          this.onGetAllCommentsByIdPro(this.activeCommentUidPro);
          this.addCommentProFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно добавихте коментар.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }

  }

  onEditPrivateCommentPro() {

    this.showLoader = true;

    if (this.editCommentProFormGroup.controls.comment.value) {

      let editCommentObj = {
        uid: this.editCommentProFormGroup.controls.uid.value,
        comment: this.editCommentProFormGroup.controls.comment.value,
        // categoryId: 0
      }

      this.data.editPrivateComment(editCommentObj).subscribe(
        data => {
          this.onGetAllCommentsById(this.activeCommentUidPro);
          this.editSidenavCommentPro.close();
          this.editCommentProFormGroup.reset();
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно редактирахте коментара.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      )

    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  onDeletePrivateCommentPro(uid: string) {

    this.showLoader = true;

    this.data.deletePrivateComment(uid).subscribe(
      data => {
        this.onGetAllCommentsById(this.activeCommentUidPro);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте вашият коментар.', 'Затвори');
      }
    )

  }

  openeditSidenavCommentPro(comment: any) {

    this.editCommentFormGroup.patchValue({
      comment: comment.comment,
      uid: comment.uid
    });

    this.editSidenavCommentPro.open();
  }

  closePro(reason: string) {
    this.editSidenavCommentPro.close();
    this.editSidenavTopicPro.close();
  }

}

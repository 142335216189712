import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  constructor(private http: HttpClient) { }

  loginProfessional(professionalObj: any) {
    const data = professionalObj;
    return this.http.post<any>(environment.professional_login, data);
  }

  professionalRegister(professionalObj: any) {
    const data = professionalObj;
    return this.http.post<any>(environment.professional_registration, data);
  }

  resetPassword(changePassObj: any) {
    const data = changePassObj;
    return this.http.put<any>(environment.change_password_pro, data);
  }

  editProfileInfo(userEditObj: any) {
    const data = userEditObj;
    return this.http.put<any>(environment.professional_edit, data);
  }

  forgottenPassword(email: any) {
    const data = email;
    return this.http.put<any>(environment.forgotten_password_professional, data);
  }
}

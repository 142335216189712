import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ProfessionalService } from '../services/professional.service';
import { SnackBarService } from '../services/snackbar.service';
// import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
// import * as moment from 'moment';

@Component({
  selector: 'app-professional-registration',
  templateUrl: './professional-registration.component.html',
  styleUrls: ['./professional-registration.component.css']
})
export class ProfessionalRegistrationComponent implements OnInit {

  @ViewChild('resetProfessionalRegisterForm', {
    static: true
  }) resetProfessionalRegisterForm: NgForm;

  @ViewChild('resetLawyerRegisterForm', {
    static: true
  }) resetLawyerRegisterForm: NgForm;

  @ViewChild('resetArbitratorRegisterForm', {
    static: true
  }) resetArbitratorRegisterForm: NgForm;

  @ViewChild('resetMediatorRegisterForm', {
    static: true
  }) resetMediatorRegisterForm: NgForm;

  @ViewChild('resetNotaryRegisterForm', {
    static: true
  }) resetNotaryRegisterForm: NgForm;

  @ViewChild('resetBailiffRegisterForm', {
    static: true
  }) resetBailiffRegisterForm: NgForm;

  @ViewChild('resetJuristRegisterForm', {
    static: true
  }) resetJuristRegisterForm: NgForm;

  @ViewChild('resetOmbudsmanRegisterForm', {
    static: true
  }) resetOmbudsmanRegisterForm: NgForm;

  @ViewChild('stepper', {
    static: true
  }) addStepper: MatStepper;

  registerProfessionalForm: FormGroup;
  getTypeForm: FormGroup;

  registerLawyerForm: FormGroup;
  registerArbitratorForm: FormGroup;
  registerMediatorForm: FormGroup;
  registerNotaryForm: FormGroup;
  registerBailiffForm: FormGroup;
  registerJuristForm: FormGroup;
  registerOmbudsmanForm: FormGroup;

  showLoader: boolean = false

  isLinear = false;

  // visibility: string = 'visibility_off';
  // inputType: string = 'password';
  validationCheck: boolean;
  professionalType: number;

  professionalObj: object;

  constructor(private formBuilder: FormBuilder, private router: Router, private data: ProfessionalService, private snackBar: SnackBarService) { }

  ngOnInit() {
    this.createForms();
  }

  createForms() {
    this.registerProfessionalForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: [, Validators.required],
      email: [, [Validators.required, Validators.email]],
      // password: ['', Validators.required],
      foreignLanguages: [''],
      approval: [false]
    });

    this.getTypeForm = this.formBuilder.group({
      type: []
    });


    this.registerLawyerForm = this.formBuilder.group({
      lawyersCollege: ['', Validators.required],
      propertyLaw: [false],
      inheritanceLaw: [false],
      commercialLaw: [false],
      copyrightRelatedLaw: [false],
      laborLaw: [false],
      familyLaw: [false],
      contractualLaw: [false],
      criminalLaw: [false],
      financialLaw: [false],
      taxLaw: [false],
      insuranceLaw: [false],
      internationalPrivateLaw: [false],
      internationalPublicLaw: [false]
    });

    this.registerArbitratorForm = this.formBuilder.group({
      competencies: ['', Validators.required]
    });

    this.registerMediatorForm = this.formBuilder.group({
      lawBranch: ['', Validators.required]
    });

    this.registerNotaryForm = this.formBuilder.group({
      regionOfOperation: ['', Validators.required],
      registrationNumber: ['', Validators.required]
    });

    this.registerBailiffForm = this.formBuilder.group({
      status: [],
    });

    this.registerJuristForm = this.formBuilder.group({
      competencies: [''],
      status: [],
    });

    this.registerOmbudsmanForm = this.formBuilder.group({
      workRegion: [''],
      ombudsmanType: [],
    });
  }

  onProfessionalRegister() {
    
    this.showLoader = true;

    this.createObj(this.getTypeForm.controls.type.value);

    if (this.registerProfessionalForm.valid && this.getTypeForm.valid) {
      this.data.professionalRegister(this.professionalObj).subscribe(
        data => {
          console.log('vytre', data)
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно се регистрирахте, ще получите имейл, за потвърждение.', 'Затвори');
          this.router.navigate(['']);
          // this.resetValidation();
        },
        error => {
          if (error.status == 401) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Вече има клиент с такъв имейл.', 'Затвори');
          } else {
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
    }
  }

  createObj(type: number) {
    if (type == 0) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          lawyersCollege: this.registerLawyerForm.controls.lawyersCollege.value,
          propertyLaw: this.registerLawyerForm.controls.propertyLaw.value,
          inheritanceLaw: this.registerLawyerForm.controls.inheritanceLaw.value,
          commercialLaw: this.registerLawyerForm.controls.commercialLaw.value,
          copyrightRelatedLaw: this.registerLawyerForm.controls.copyrightRelatedLaw.value,
          laborLaw: this.registerLawyerForm.controls.laborLaw.value,
          familyLaw: this.registerLawyerForm.controls.familyLaw.value,
          contractualLaw: this.registerLawyerForm.controls.contractualLaw.value,
          criminalLaw: this.registerLawyerForm.controls.criminalLaw.value,
          financialLaw: this.registerLawyerForm.controls.financialLaw.value,
          taxLaw: this.registerLawyerForm.controls.taxLaw.value,
          insuranceLaw: this.registerLawyerForm.controls.insuranceLaw.value,
          internationalPrivateLaw: this.registerLawyerForm.controls.internationalPrivateLaw.value,
          internationalPublicLaw: this.registerLawyerForm.controls.internationalPublicLaw.value
        }
      }
    } else if (type == 1) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          competencies: this.registerArbitratorForm.controls.competencies.value,
        }
      }
    } else if (type == 2) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          lawBranch: this.registerMediatorForm.controls.lawBranch.value,
        }
      }
    } else if (type == 3) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          regionOfOperation: this.registerNotaryForm.controls.regionOfOperation.value,
          registrationNumber: this.registerNotaryForm.controls.registrationNumber.value
        }
      }
    } else if (type == 4) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          status: this.registerBailiffForm.controls.status.value
        }
      }
    } else if (type == 5) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          competencies: this.registerJuristForm.controls.competencies.value,
          status: this.registerJuristForm.controls.status.value,
        }
      }
    } else if (type == 6) {
      this.professionalObj = {
        name: this.registerProfessionalForm.controls.name.value,
        phone: this.registerProfessionalForm.controls.phone.value,
        userEmail: this.registerProfessionalForm.controls.email.value,
        // password: this.registerProfessionalForm.controls.password.value,
        registerAgreement: this.registerProfessionalForm.controls.approval.value,
        foreignLanguages: this.registerProfessionalForm.controls.foreignLanguages.value,

        type: this.getTypeForm.controls.type.value,

        details: {
          workRegion: this.registerOmbudsmanForm.controls.workRegion.value,
          ombudsmanType: this.registerOmbudsmanForm.controls.ombudsmanType.value,
        }
      }
    }

  }

  chooseProfessional(professionalType: number) {
    this.professionalType = professionalType;
    this.getTypeForm.patchValue({
      type: this.professionalType,
    })
    this.getTypeForm.controls.type.value
    console.log('awdawd', this.getTypeForm.controls.type.value)
    this.onValidCheck();
  }

  // resetValidation() {
  //   this.registerLawyerForm.reset();
  //   this.registerProfessionalForm.reset();
  // }

  // resetProfessionalsValidation() {
  //   this.registerLawyerForm.reset();
  //   this.registerProfessionalForm.reset();
  // }

  // passwordVisibility() {
  //   if (this.visibility == 'visibility') {
  //     this.visibility = 'visibility_off';
  //     this.inputType = 'password';
  //   } else {
  //     this.visibility = 'visibility';
  //     this.inputType = 'text';
  //   }
  // }

  onValidCheck() {
    // this.resetValidation();
    console.log("----", this.validationCheck);
    if (this.registerProfessionalForm.valid) {
      this.validationCheck = true;
    } else {
      this.validationCheck = false;
    }
    this.validationCheck = true;
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OepRegistarComponent } from './oep-registar/oep-registar.component';
import { OepRegistratsiaComponent } from './oep-registratsia/oep-registratsia.component';
import { OepRakovodstvoComponent } from './oep-rakovodstvo/oep-rakovodstvo.component';
import { PoleznaInformatsiaComponent } from './polezna-informatsia/polezna-informatsia.component';
import { OepReshavaneProblemComponent } from './oep-reshavane-problem/oep-reshavane-problem.component';
import { InformatsiaIVrazkiComponent } from './informatsia-i-vrazki/informatsia-i-vrazki.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { DsIskamDaZnamComponent } from './ds-iskam-da-znam/ds-iskam-da-znam.component';
import { FOtvorenaVrataComponent } from './f-otvorena-vrata/f-otvorena-vrata.component';
import { ZaProektaComponent } from './za-proekta/za-proekta.component';
import { NoviniComponent } from './novini/novini.component';
import { DobriPraktikiComponent } from './dobri-praktiki/dobri-praktiki.component';
import { HoraSUvrezhdaniaSadaComponent } from './hora-s-uvrezhdania-sada/hora-s-uvrezhdania-sada.component';
import { HoraSUvrezhdaniaPravaComponent } from './hora-s-uvrezhdania-prava/hora-s-uvrezhdania-prava.component';
import { SignaliComponent } from './signali/signali.component';
import { MatCardModule } from '@angular/material/card';
import { ForumPravilaComponent } from './forum-pravila/forum-pravila.component';
import { ForumOsnovenComponent } from './forum-osnoven/forum-osnoven.component';
import { EMediatsiyaComponent } from './e-mediatsiya/e-mediatsiya.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MoitePravaComponent } from './moite-prava/moite-prava.component';
import { ProfileComponent } from './profile/profile.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogDsIskamDaZnamComponent } from './dialog-ds-iskam-da-znam/dialog-ds-iskam-da-znam.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { ProfessionalRegistrationComponent } from './professional-registration/professional-registration.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProfessionalProfileComponent } from './professional-profile/professional-profile.component';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DialogApprovalComponent } from './dialog-approval/dialog-approval.component';
import { DialogFOtvorenaVrataComponent } from './dialog-f-otvorena-vrata/dialog-f-otvorena-vrata.component';
import { DialogRedirectComponent } from './dialog-redirect/dialog-redirect.component';
import { AlgorithmsComponent } from './algorithms/algorithms.component';
import { CourtComponent } from './informatsia-i-vrazki-all/court/court.component';
import { ProsecutorComponent } from './informatsia-i-vrazki-all/prosecutor/prosecutor.component';
import { ArbitrationComponent } from './informatsia-i-vrazki-all/arbitration/arbitration.component';
import { MediatorsComponent } from './informatsia-i-vrazki-all/mediators/mediators.component';
import { InvestigativeBodiesComponent } from './informatsia-i-vrazki-all/investigative-bodies/investigative-bodies.component';
import { InvestigativeBodiesSledstveniComponent } from './informatsia-i-vrazki-all/investigative-bodies-sledstveni/investigative-bodies-sledstveni.component';
import { BailiffsComponent } from './informatsia-i-vrazki-all/bailiffs/bailiffs.component';
import { EntryJudgesComponent } from './informatsia-i-vrazki-all/entry-judges/entry-judges.component';
import { NotariesComponent } from './informatsia-i-vrazki-all/notaries/notaries.component';
import { LawyersComponent } from './informatsia-i-vrazki-all/lawyers/lawyers.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ImamSporComponent } from './algorithms-all/imam-spor/imam-spor.component';
import { TarsiaZashtitaComponent } from './algorithms-all/tarsia-zashtita/tarsia-zashtita.component';
import { TarsiaSposobComponent } from './algorithms-all/tarsia-sposob/tarsia-sposob.component';
import { ObrazuvanoSledstvenoComponent } from './algorithms-all/obrazuvano-sledstveno/obrazuvano-sledstveno.component';
import { ObrazuvanoSadebnoComponent } from './algorithms-all/obrazuvano-sadebno/obrazuvano-sadebno.component';
import { ObrazuvanoIzpalnitelnoComponent } from './algorithms-all/obrazuvano-izpalnitelno/obrazuvano-izpalnitelno.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { VatreshnaSredaComponent } from './vatreshna-sreda/vatreshna-sreda.component';
import { KakviSaPravataMiComponent } from './kakvi-sa-pravata-mi/kakvi-sa-pravata-mi.component';
import { DialogKakviSaPravataMiComponent } from './dialog-kakvi-sa-pravata-mi/dialog-kakvi-sa-pravata-mi.component';
import { AlgorithmByIdComponent } from './algorithm-by-id/algorithm-by-id.component';
import { DialogShowRelationComponent } from './dialog-show-relation/dialog-show-relation.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NoviniByIdComponent } from './novini-by-id/novini-by-id.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TokenInterceptorService } from './interceptor/token-interceptor.service';
import { UserService } from './services/user.service';
import { ForumSpetsializiranComponent } from './forum-spetsializiran/forum-spetsializiran.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminComponent } from './admin/admin/admin.component';
import { SidebarComponent } from './admin/sidebar/sidebar.component';
import { AdminHeaderComponent } from './admin/admin-header/admin-header.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatMenuModule } from '@angular/material/menu';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminProfessionalsComponent } from './admin/admin-professionals/admin-professionals.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminNoviniComponent } from './admin/admin-novini/admin-novini.component';
import { AdminAlgorithmsComponent } from './admin/admin-algorithms/admin-algorithms.component';
import { AdminForumComponent } from './admin/admin-forum/admin-forum.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DialogForgottenPasswordComponent } from './dialog-forgotten-password/dialog-forgotten-password.component';
import { DialogConfirmPasswordComponent } from './dialog-confirm-password/dialog-confirm-password.component';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: 'AIzaSyA9S7DY0khhn9JYcfyRWb1F6Rd2rwtF_mA'
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    OepRegistarComponent,
    OepRegistratsiaComponent,
    OepRakovodstvoComponent,
    PoleznaInformatsiaComponent,
    OepReshavaneProblemComponent,
    InformatsiaIVrazkiComponent,
    DsIskamDaZnamComponent,
    FOtvorenaVrataComponent,
    ZaProektaComponent,
    NoviniComponent,
    DobriPraktikiComponent,
    HoraSUvrezhdaniaSadaComponent,
    HoraSUvrezhdaniaPravaComponent,
    SignaliComponent,
    ForumPravilaComponent,
    ForumOsnovenComponent,
    EMediatsiyaComponent,
    MoitePravaComponent,
    ProfileComponent,
    DialogDsIskamDaZnamComponent,
    UserLoginComponent,
    UserRegistrationComponent,
    ProfessionalRegistrationComponent,
    UserProfileComponent,
    ProfessionalProfileComponent,
    DialogApprovalComponent,
    DialogFOtvorenaVrataComponent,
    DialogRedirectComponent,
    AlgorithmsComponent,
    CourtComponent,
    ProsecutorComponent,
    ArbitrationComponent,
    MediatorsComponent,
    InvestigativeBodiesComponent,
    InvestigativeBodiesSledstveniComponent,
    BailiffsComponent,
    EntryJudgesComponent,
    NotariesComponent,
    LawyersComponent,
    ImamSporComponent,
    TarsiaZashtitaComponent,
    TarsiaSposobComponent,
    ObrazuvanoSledstvenoComponent,
    ObrazuvanoSadebnoComponent,
    ObrazuvanoIzpalnitelnoComponent,
    VatreshnaSredaComponent,
    KakviSaPravataMiComponent,
    DialogKakviSaPravataMiComponent,
    AlgorithmByIdComponent,
    DialogShowRelationComponent,
    TruncatePipe,
    NoviniByIdComponent,
    ForumSpetsializiranComponent,
    AdminLoginComponent,
    AdminComponent,
    SidebarComponent,
    AdminHeaderComponent,
    AdminProfileComponent,
    AdminUsersComponent,
    AdminProfessionalsComponent,
    AdminForumComponent,
    AdminAlgorithmsComponent,
    AdminNoviniComponent,
    AdminHomeComponent,
    ChangePasswordComponent,
    DialogForgottenPasswordComponent,
    DialogConfirmPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatCheckboxModule,
    MatRadioModule,
    MatGridListModule,
    NgxPaginationModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSidenavModule,
    FontPickerModule,
    NgxDatatableModule,
    MatMenuModule,
    MatProgressBarModule
  ],
  providers: [
    UserService,
    MatDatepickerModule,
    MatNativeDateModule,
    DatePipe,
    CookieService,
    HeaderComponent,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: FONT_PICKER_CONFIG, useValue: DEFAULT_FONT_PICKER_CONFIG, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogDsIskamDaZnamComponent,
    DialogApprovalComponent,
    DialogFOtvorenaVrataComponent,
    DialogRedirectComponent,
    DialogKakviSaPravataMiComponent,
    DialogShowRelationComponent,
    DialogForgottenPasswordComponent,
    DialogConfirmPasswordComponent
  ]
})
export class AppModule { }

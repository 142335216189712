import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-approval',
  templateUrl: './dialog-approval.component.html',
  styleUrls: ['./dialog-approval.component.css']
})
export class DialogApprovalComponent implements OnInit {

  // id: number;
  // title: any;
  // message: any; 
  
  constructor(private dialogRef: MatDialogRef<DialogApprovalComponent>, @Inject(MAT_DIALOG_DATA) data: any) { 
    // this.id = data.id;
    // this.title = data.title;
    // this.message = data.message;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}

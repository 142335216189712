import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatSidenav } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { AdminService } from '../../services/admin.service';
import { SnackBarService } from '../../services/snackbar.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DialogConfirmPasswordComponent } from 'src/app/dialog-confirm-password/dialog-confirm-password.component';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {

  adminId: string;
  adminName: string;
  adminEmail: string;
  adminIsMaster: string;
  adminPermission: number;
  algorithmPermission: number;
  forumPermission: number;
  newsPermission: number;
  pagePermission: number;

  enableEdit: boolean = true;
  showLoader: boolean = true;

  @ViewChild('resetEditForm', {
    static: true
  }) resetEditForm: NgForm;

  @ViewChild('resetPartnerRegFormSecond', {
    static: true
  }) resetResetPasswordRegForm: NgForm;

  @ViewChild('resetAddAdminAccountForm', {
    static: true
  }) resetAddAdminAccountForm: NgForm;

  @ViewChild('addSidenavAdmin', {
    static: false
  }) addSidenavAdmin: MatSidenav;

  editFormGroup: FormGroup;
  resetPasswordFormGroup: FormGroup;
  addAdminAccountFormGroup: FormGroup;

  visibility: string = 'visibility_off';
  inputType: string = 'password';
  validationCheck: boolean;

  rowsAdmins = <any>[];
  columnsAdmins = <any>[];

  @Input() prop!: number;
  loadingIndicator: boolean;
  reorderable = true;
  swapColumns = true;
  SelectionType = SelectionType;

  limit: number = 10;
  offset: number = 0;
  cPage: number = 1;
  totalResult: number;

  @ViewChild('actionTemplate', { static: false }) actionTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', { static: false }) headerTemplate: TemplateRef<any>;
  @ViewChild('headerActionTemplate', { static: false }) headerActionTemplate: TemplateRef<any>;
  @ViewChild('permissions', { static: false }) permissions: TemplateRef<any>;
  @ViewChild('mainAdmin', { static: false }) mainAdmin: TemplateRef<any>;

  TableSettings = {
    messages: {
      emptyMessage: `
        <div class="padding-one" style>
        <span>Нямате заявки, за показване.</span>
        </div>
        `
    }
  }
  messages = this.TableSettings.messages;
  title: string = 'Добавяне на Админ акаунт';

  constructor(private formBuilder: FormBuilder, private cookieService: CookieService, private data: AdminService, private snackBar: SnackBarService, private dialog: MatDialog) { }

  ngAfterViewInit(): void {
    this.makeTable();
  }

  ngOnInit() {
    this.getCookies();
    this.makeTable();
    this.resetPagination();
    this.onGetAdminsPaginated(this.offset, this.limit);

    this.createForms();
    this.disableFields();
  }

  getCookies() {
    this.adminPermission = parseInt(this.cookieService.get('adminPermission'));
    this.adminId = this.cookieService.get('adminId');
    this.adminName = this.cookieService.get('adminName');
    this.adminEmail = this.cookieService.get('adminEmail');
    this.adminIsMaster = this.cookieService.get('isMaster');
  }

  makeTable() {

    this.columnsAdmins = [
      { name: 'Име', prop: "name", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Имейл', prop: "email", width: 100, value: 1, headerTemplate: this.headerTemplate },
      { name: 'Достъп', width: 50, sortable: true, cellTemplate: this.permissions, headerTemplate: this.headerTemplate },
      { name: 'Главен Админ', width: 50, sortable: true, cellTemplate: this.mainAdmin, headerTemplate: this.headerTemplate },
      { name: 'Действие', width: 50, sortable: true, cellTemplate: this.actionTemplate, headerTemplate: this.headerActionTemplate }
    ];

  }

  createForms() {
    this.editFormGroup = this.formBuilder.group({
      adminName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.resetPasswordFormGroup = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required]
    });

    this.addAdminAccountFormGroup = this.formBuilder.group({
      id: [],
      name: [''],
      email: [''],
      adminPermission: [true],
      algorithmPermission: [true],
      forumPermission: [true],
      newsPermission: [true],
      pagePermission: [true]
    });
  }


  onEditProfileInfo() {

    this.showLoader = true;

    if (this.editFormGroup.controls.adminName.valid) {

      let adminEditObj = {
        id: parseInt(this.adminId),
        name: this.editFormGroup.controls.adminName.value
      }

      this.data.editProfileInfo(adminEditObj).subscribe(
        data => {
          this.cookieService.set('adminName', data['name']);
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно променихте информацията за вашия профил.', 'Затвори');
        },
        error => {
          if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  onResetPassword() {
    console.log('Lets change it!')

    this.showLoader = true;

    if (this.resetPasswordFormGroup.controls.newPassword.valid && this.resetPasswordFormGroup.controls.oldPassword.valid) {

      let changePassObj = {
        id: parseInt(this.adminId),
        newPassword: this.resetPasswordFormGroup.controls.newPassword.value,
        oldPassword: this.resetPasswordFormGroup.controls.oldPassword.value
      }

      this.data.resetPassword(changePassObj).subscribe(
        data => {
          console.log('awdawdawd', data)
          this.showLoader = false;
          this.snackBar.openSnackBar('Успешно променихте паролата си.', 'Затвори');
        },
        error => {
          if (error.status == 401) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Грешно сте въвели старата парола', 'Затвори');
          } else if (error.status != 200) {
            this.showLoader = false;
            this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
          }
        }
      );
    } else {
      this.showLoader = false;
      this.snackBar.openSnackWrongBar('Не сте попълнили правилно формуляра.', 'Затвори');
    }
  }

  onAddAdminAccount() {

    this.showLoader = true;

    console.log('this.addAdminAccountFormGroup.value', this.addAdminAccountFormGroup.value)

    if (!this.addAdminAccountFormGroup.controls.id.value) {
      if (this.addAdminAccountFormGroup.controls.name.value && this.addAdminAccountFormGroup.controls.email.value) {

        let adminObj = {
          name: this.addAdminAccountFormGroup.controls.name.value,
          email: this.addAdminAccountFormGroup.controls.email.value,
          adminPermission: this.addAdminAccountFormGroup.controls.adminPermission.value,
          algorithmPermission: this.addAdminAccountFormGroup.controls.algorithmPermission.value,
          forumPermission: this.addAdminAccountFormGroup.controls.forumPermission.value,
          newsPermission: this.addAdminAccountFormGroup.controls.newsPermission.value,
          pagePermission: this.addAdminAccountFormGroup.controls.pagePermission.value,
        }

        this.data.adminRegistration(adminObj).subscribe(
          data => {
            this.resetPagination();
            this.onGetAdminsPaginated(this.offset, this.limit);
            this.addSidenavAdmin.close();
            this.addAdminAccountFormGroup.reset();
            this.showLoader = false;
            this.snackBar.openSnackBar('Успешно добавихте админ акаунт.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          }
        )

      } else {
        this.showLoader = false;
        this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
      }
    } else {
      if (this.addAdminAccountFormGroup.controls.name.value && this.addAdminAccountFormGroup.controls.email.value) {

        let adminEditObj = {
          id: this.addAdminAccountFormGroup.controls.id.value,
          name: this.addAdminAccountFormGroup.controls.name.value,
          email: this.addAdminAccountFormGroup.controls.email.value,
          adminPermission: this.addAdminAccountFormGroup.controls.adminPermission.value,
          algorithmPermission: this.addAdminAccountFormGroup.controls.algorithmPermission.value,
          forumPermission: this.addAdminAccountFormGroup.controls.forumPermission.value,
          newsPermission: this.addAdminAccountFormGroup.controls.newsPermission.value,
          pagePermission: this.addAdminAccountFormGroup.controls.pagePermission.value,
        }

        this.data.adminEdit(adminEditObj).subscribe(
          data => {
            this.resetPagination();
            this.onGetAdminsPaginated(this.offset, this.limit);
            this.addSidenavAdmin.close();
            this.addAdminAccountFormGroup.reset();
            this.showLoader = false;
            this.snackBar.openSnackBar('Успешно редактирахте админ акаунт.', 'Затвори');
          },
          error => {
            if (error.status != 200) {
              this.showLoader = false;
              this.snackBar.openSnackWrongBar('Нещо се обърка.', 'Затвори');
            }
          }
        )

      } else {
        this.showLoader = false;
        this.snackBar.openSnackWrongBar('Не сте попълнили правилно формата.', 'Затвори');
      }
    }

  }

  openAddAdminAccount() {
    this.title = 'Добавяне на Админ акаунт';
    this.addSidenavAdmin.open();
  }

  openEditAdminAccount(row) {
    console.log('row', row)
    this.title = 'Редакция на Админ акаунт';

    this.addAdminAccountFormGroup.patchValue({
      id: row.id,
      name: row.name,
      email: row.email,
      adminPermission: row.adminPermission,
      algorithmPermission: row.algorithmPermission,
      forumPermission: row.forumPermission,
      newsPermission: row.newsPermission,
      pagePermission: row.pagePermission,
    });

    this.addAdminAccountFormGroup.controls.name.disable();
    this.addAdminAccountFormGroup.controls.email.disable();

    this.addSidenavAdmin.open();
  }

  onGetAdminsPaginated(offset: number, limit: number) {
    this.showLoader = true;

    this.data.getAdminsPaginated(offset, limit).subscribe(
      data => {
        console.log('respons data', data)

        this.totalResult = (Math.floor(data.totalResult / 10)) * 10;
        if (this.totalResult === data.totalResult) {
          this.totalResult = this.totalResult - Math.floor(data.totalResult / 10);
        }
        this.rowsAdmins = data.resultList;
        this.showLoader = false;
      }
    );
  }

  openConfirmPassword(row) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;


    dialogConfig.data = {
      newMainAdminId: row.id,
      newMainAdminName: row.name
    };

    let dialogRef = this.dialog.open(DialogConfirmPasswordComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.onGetAdminsPaginated(this.offset, this.limit);
    });


  }

  onDeleteAdmin(row) {
    console.log('DELETE', row.id);

    this.showLoader = true;

    this.data.deleteAdmin(row.id).subscribe(
      data => {
        this.resetPagination();
        this.onGetAdminsPaginated(this.offset, this.limit);
        this.showLoader = false;
        this.snackBar.openSnackBar('Успешно изтрихте админ акаунт.', 'Затвори');
      }
    );
  }

  adminPageChanged(event) {
    this.cPage = event;
    this.offset = 10 * (event - 1);
    if (this.limit == this.offset || this.limit < this.offset) {
      this.onGetAdminsPaginated(this.offset, this.limit);
    } else if (event == 1) {
      this.resetPagination();
      this.onGetAdminsPaginated(this.offset, this.limit);
    }
  }

  resetPagination() {
    this.limit = 10;
    this.offset = 0;
    this.cPage = 1;
  }

  enableField(status: boolean) {
    if (status == false) {
      this.enableFields();
      this.enableEdit = status;
    } else {
      this.disableFields();
      this.enableEdit = status;
    }
  }

  disableFields() {
    this.editFormGroup.controls.adminName.disable();
    this.editFormGroup.controls.email.disable();
    this.resetPasswordFormGroup.controls.oldPassword.disable();
    this.resetPasswordFormGroup.controls.newPassword.disable();
  }

  enableFields() {
    this.editFormGroup.controls.adminName.enable();
    this.editFormGroup.controls.email.disable();
    this.resetPasswordFormGroup.controls.oldPassword.enable();
    this.resetPasswordFormGroup.controls.newPassword.enable();
  }

  passwordVisibility() {
    if (this.visibility == 'visibility') {
      this.visibility = 'visibility_off';
      this.inputType = 'password';
    } else {
      this.visibility = 'visibility';
      this.inputType = 'text';
    }
  }

  close(reason: string) {

    if (this.addAdminAccountFormGroup.controls.id) {
      this.addAdminAccountFormGroup.controls.name.enable();
      this.addAdminAccountFormGroup.controls.email.enable();
      this.addAdminAccountFormGroup.patchValue({
        id: null,
        name: '',
        email: '',
        adminPermission: true,
        algorithmPermission: true,
        forumPermission: true,
        newsPermission: true,
        pagePermission: true,
      });

      this.addSidenavAdmin.close();

    } else {

      this.addSidenavAdmin.close();

    }

  }

}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgorithmsService } from '../services/algorithms.service';
import { DialogShowRelationComponent } from '../dialog-show-relation/dialog-show-relation.component';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Font } from 'ngx-font-picker';
import { accessibility } from '../services/constants.service';
import { Fonts } from '../models/fonts.interface';

@Component({
  selector: 'app-algorithm-by-id',
  templateUrl: './algorithm-by-id.component.html',
  styleUrls: ['./algorithm-by-id.component.css']
})
export class AlgorithmByIdComponent implements OnInit {

  // Accessibility variables
  fonts: Fonts[] = accessibility.fonts
  fontSizes: string[] = accessibility.fontSizes;

  toggle: string = 'close';
  currentSizePosition: number = 1;

  public font: Font = new Font({
    family: ' ',
    size: ' ',
    style: 'regular',
    styles: ['regular'],
  });
  // End

  private routeSub: Subscription;

  algorithmId: any;
  algorithmList: any;

  stepOneChoices: any;
  stepTwoChoices: any;
  relations: any;
  answers: any;
  stepOneChoiceNumber: number;
  algorithmType: number;
  title: any;
  titleOne: any;
  titleTwo: any;

  constructor(private router: Router, private route: ActivatedRoute, private data: AlgorithmsService, private dialog: MatDialog) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      console.log(params) //log the entire params object
      console.log(params['id']) //log the value of id
      this.algorithmId = params['id'];
    });
    this.onGetAlgorithmById(this.algorithmId);
  }

  onGetAlgorithmById(algorithmId: any) {

    this.data.getAlgorithmById(algorithmId).subscribe(
      data => {

        if (data.type == 0) {

          this.stepOneChoices = data.stepOneChoices;
          this.relations = data.relations;
          this.answers = data.answers;
          this.algorithmType = data.type;
          this.title = data.stepOne;

        } else if (data.type == 1) {

          this.stepOneChoices = data.stepOneChoices;
          this.stepTwoChoices = data.stepTwoChoices;
          this.relations = data.relations;
          this.answers = data.answers;
          this.algorithmType = data.type;
          this.titleOne = data.stepOne;
          this.titleTwo = data.stepTwo;

        } else if (data.type == 2) {

          this.stepOneChoices = data.stepOneChoices;
          this.stepTwoChoices = data.stepTwoChoices;
          this.relations = data.relations;
          this.answers = data.answers;
          this.algorithmType = data.type;
          this.titleOne = data.stepOne;
          this.titleTwo = data.stepTwo;

        }

        // this.showLoader = false;
      }
    );

  }

  // ALGORITHM TYPE = 0
  onShowRelation(stepOneChoiceNumber: number) {

      const answerIndex = this.answers.findIndex(element => element.number == stepOneChoiceNumber);

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = false;

      dialogConfig.data = {
        id: '0',
        title: 'НАСОКА!',
        answerNumber: this.answers[answerIndex].answer
      };

      this.dialog.open(DialogShowRelationComponent, dialogConfig);

  }

  // ALGORITHM TYPE = 1
  onShowTwoNoRelations(stepOneChoiceNumber: number, stepTwoChoiceNumber: number) {

    const relationIndex = this.relations.findIndex(element => element.stepOneChoiceNumber == stepOneChoiceNumber && element.stepTwoChoiceNumber == stepTwoChoiceNumber);
    const answerIndex = this.answers.findIndex(element => element.number == this.relations[relationIndex].answerNumber);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      id: '0',
      title: 'НАСОКА!',
      answerNumber: this.answers[answerIndex].answer
    };

    this.dialog.open(DialogShowRelationComponent, dialogConfig);

  }

  // ALGORITHM TYPE = 2
  setFirstChoice(stepOneChoiceNumber: number) {
    this.stepOneChoiceNumber = stepOneChoiceNumber;
  }

  onShowTwoRelations(stepOneChoiceNumber: number, stepTwoChoiceNumber: number) {

    const relationIndex = this.relations.findIndex(element => element.stepOneChoiceNumber == stepOneChoiceNumber && element.stepTwoChoiceNumber == stepTwoChoiceNumber);
    const answerIndex = this.answers.findIndex(element => element.number == this.relations[relationIndex].answerNumber);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      id: '0',
      title: 'НАСОКА!',
      answerNumber: this.answers[answerIndex].answer
    };

    this.dialog.open(DialogShowRelationComponent, dialogConfig);

  }

  // Accessibility methods
  toggleAccessibility() {
    if (this.toggle == 'open') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "-250px";
      this.toggle = 'close';
    } else if (this.toggle == 'close') {
      const accessibilityWrap = document.getElementsByClassName("accessibility-wrap")[0] as HTMLElement;
      accessibilityWrap.style.right = "0px";
      this.toggle = 'open';
    }
  }

  onChangeFontSize(fontSizeCondition) {
    if (fontSizeCondition == 'Zoom In') {
      if (this.currentSizePosition < this.fontSizes.length - 1) {
        this.currentSizePosition++;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    } else if (fontSizeCondition == 'Zoom Out') {
      if (this.currentSizePosition > 1) {
        this.currentSizePosition--;
      }
      this.font.size = this.fontSizes[this.currentSizePosition];
    }
  }

  onChangeFontFont(newFont) {
    this.font.family = newFont;
  }

  dropAccessibilitySettings() {
    this.currentSizePosition = 0;
    this.font.size = this.fontSizes[0];
    this.font.family = ' ';
  }
  // End

}

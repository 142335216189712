import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informatsia-i-vrazki',
  templateUrl: './informatsia-i-vrazki.component.html',
  styleUrls: ['./informatsia-i-vrazki.component.css']
})
export class InformatsiaIVrazkiComponent implements OnInit {

  breakpoint: number;

  constructor() { }

  ngOnInit() {
    if(window.innerWidth > 1200){
      this.breakpoint =  4 ;
    }else if(window.innerWidth > 701 && window.innerWidth <= 1199){
      this.breakpoint =  2 ;
    }else if(window.innerWidth <= 700){
      this.breakpoint =  1 ;
    }
  }

  onResize(event) {
    if(event.target.innerWidth > 1200){
      this.breakpoint =  4 ;
    }else if(event.target.innerWidth > 701 && event.target.innerWidth <= 1199){
      this.breakpoint =  2 ;
    }else if(event.target.innerWidth <= 700){
      this.breakpoint =  1 ;
    }
  }

}

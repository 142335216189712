import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-kakvi-sa-pravata-mi',
  templateUrl: './dialog-kakvi-sa-pravata-mi.component.html',
  styleUrls: ['./dialog-kakvi-sa-pravata-mi.component.css']
})
export class DialogKakviSaPravataMiComponent implements OnInit {

  id: number;
  title: any;
  message: any;

  constructor(private dialogRef: MatDialogRef<DialogKakviSaPravataMiComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.id = data.id;
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
